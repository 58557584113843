import { Styles } from './Card.styles';

import online from 'img/online.png';

export const Card = () => {
  const academiaLogo = (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8333.000000 8333.000000"
      preserveAspectRatio="xMidYMid meet">
      <g transform="translate(0.000000,8333.000000) scale(0.100000,-0.100000)" fill="var(--text)" stroke="none">
        <path
          d="M20580 78805 c-154 -27 -373 -75 -635 -141 -379 -95 -641 -146 -965
-186 l-185 -23 -1895 0 c-2052 0 -1999 1 -2460 -55 -1003 -123 -1640 -404
-2061 -909 -383 -458 -586 -1061 -666 -1974 -21 -247 -24 -952 -5 -1167 59
-647 180 -1116 404 -1565 254 -507 561 -876 943 -1133 335 -225 717 -369 1094
-412 155 -18 434 -8 536 20 312 85 561 326 642 620 27 99 24 375 -6 485 -28
103 -73 178 -136 225 -77 56 -283 178 -564 332 -359 197 -502 294 -678 461
-288 274 -420 546 -490 1012 -14 98 -18 184 -18 460 0 307 2 352 22 460 108
588 344 828 973 986 223 57 557 102 819 111 l84 3 69 -205 c276 -818 382
-1193 708 -2506 206 -829 246 -978 315 -1159 292 -766 902 -1331 1697 -1570
125 -37 350 -82 513 -102 195 -23 590 -23 780 1 989 124 1773 650 2165 1451
119 242 187 462 232 746 20 128 22 180 22 464 0 350 -10 462 -63 733 -78 399
-217 749 -448 1134 -217 362 -479 707 -820 1082 l-63 69 188 102 c659 359
1036 664 1157 939 46 105 58 170 52 301 -7 146 -23 210 -87 340 -139 283 -438
519 -732 579 -110 23 -275 19 -438 -9z m-2790 -2405 c449 -32 831 -99 1081
-190 400 -146 733 -416 982 -795 353 -540 454 -1237 249 -1714 -131 -304 -430
-568 -772 -684 -160 -55 -269 -71 -480 -71 -210 0 -296 11 -454 61 -178 56
-293 131 -446 290 -207 215 -312 401 -405 718 -57 193 -225 815 -290 1077
-121 478 -290 1014 -406 1286 l-20 45 408 -6 c224 -4 473 -11 553 -17z"
        />
        <path
          d="M41232 76703 c-37 -18 -63 -52 -80 -102 -8 -23 -12 -135 -12 -338 l0
-304 -80 65 c-129 106 -202 134 -360 143 -335 17 -572 -175 -636 -516 -23
-123 -15 -386 15 -490 92 -316 320 -494 611 -478 152 9 267 60 383 171 32 31
60 56 61 56 2 0 6 -27 9 -60 10 -112 80 -176 183 -168 91 8 139 57 155 158 6
41 9 376 7 905 l-3 840 -23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z
m-337 -836 c57 -28 133 -105 163 -163 42 -83 56 -154 56 -279 0 -173 -33 -272
-120 -365 -63 -68 -128 -94 -229 -95 -68 0 -89 4 -137 28 -135 66 -211 221
-210 432 0 298 142 483 362 471 45 -2 81 -11 115 -29z"
        />
        <path
          d="M46045 76681 c-97 -44 -133 -148 -85 -245 27 -56 75 -87 148 -93 131
-12 219 86 197 218 -19 109 -151 170 -260 120z"
        />
        <path
          d="M73712 76673 c-37 -18 -63 -52 -80 -102 -8 -23 -12 -135 -12 -337 l0
-304 -82 67 c-45 36 -106 77 -134 90 -158 72 -385 69 -542 -8 -78 -38 -180
-132 -226 -208 -20 -31 -49 -100 -67 -152 -30 -87 -32 -106 -37 -259 -7 -248
26 -389 125 -543 61 -96 133 -161 228 -208 98 -48 173 -63 285 -56 152 9 270
63 388 176 29 28 55 51 56 51 2 0 6 -27 9 -60 7 -77 35 -122 93 -150 79 -39
165 -18 216 51 36 48 39 132 36 994 l-3 840 -23 47 c-17 35 -34 52 -66 67 -51
25 -119 27 -164 4z m-337 -836 c57 -28 133 -105 163 -163 42 -83 56 -154 56
-279 0 -224 -74 -371 -219 -437 -76 -35 -191 -33 -267 5 -135 66 -211 221
-210 432 0 298 142 483 362 471 45 -2 81 -11 115 -29z"
        />
        <path
          d="M35629 76155 c-255 -41 -438 -193 -439 -363 0 -99 53 -152 151 -152
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 157 0 202 -17
243 -94 34 -62 51 -167 28 -174 -122 -34 -243 -64 -423 -101 -236 -50 -292
-66 -353 -102 -62 -36 -131 -113 -162 -179 -25 -53 -28 -72 -28 -160 0 -87 3
-107 26 -155 40 -83 80 -132 143 -174 97 -64 153 -79 301 -80 187 -1 289 32
464 149 39 26 73 46 74 44 2 -2 25 -35 51 -72 56 -80 122 -127 175 -127 49 1
111 31 144 69 36 43 35 84 -4 230 l-31 115 -2 335 c-3 356 -8 402 -56 505 -49
104 -154 177 -300 211 -102 23 -366 29 -481 10z m481 -767 c-1 -62 -21 -205
-35 -248 -47 -142 -250 -246 -412 -212 -180 38 -233 249 -86 342 31 20 88 39
182 61 75 17 177 42 226 54 117 30 125 30 125 3z"
        />
        <path
          d="M37335 76156 c-255 -50 -428 -196 -515 -434 -39 -109 -54 -221 -47
-366 6 -133 23 -209 66 -303 63 -134 155 -228 286 -293 107 -53 177 -70 320
-77 318 -15 576 105 681 316 40 81 41 172 1 224 -39 51 -80 70 -137 65 -63 -6
-88 -27 -160 -135 -69 -104 -102 -135 -180 -172 -75 -36 -209 -37 -286 -2 -99
43 -178 150 -209 282 -17 75 -19 211 -4 294 34 186 160 318 316 332 129 12
229 -34 312 -141 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 46 0 67 5 95
24 42 29 69 79 69 130 0 117 -122 267 -280 344 -47 23 -112 49 -145 58 -83 21
-292 27 -380 10z"
        />
        <path
          d="M38909 76155 c-255 -41 -438 -193 -439 -363 0 -99 53 -152 151 -152
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 157 0 202 -17
243 -94 34 -62 51 -167 28 -174 -122 -34 -243 -64 -423 -101 -236 -50 -292
-66 -353 -102 -62 -36 -131 -113 -162 -179 -25 -53 -28 -72 -28 -160 0 -87 3
-107 26 -155 40 -83 80 -132 143 -174 97 -64 153 -79 301 -80 187 -1 289 32
464 149 39 26 73 46 74 44 2 -2 25 -35 51 -72 56 -80 122 -127 175 -127 49 1
111 31 144 69 36 43 35 84 -4 230 l-31 115 -2 335 c-3 356 -8 402 -56 505 -49
104 -154 177 -300 211 -102 23 -366 29 -481 10z m481 -767 c-1 -62 -21 -205
-35 -248 -47 -142 -250 -246 -412 -212 -180 38 -233 249 -86 342 31 20 88 39
182 61 75 17 177 42 226 54 117 30 125 30 125 3z"
        />
        <path
          d="M42335 76155 c-265 -48 -454 -226 -527 -495 -31 -117 -32 -356 0
-471 69 -256 237 -421 492 -485 114 -29 357 -27 464 4 189 55 338 167 382 290
39 109 -18 190 -128 180 -42 -5 -57 -13 -125 -74 -152 -136 -220 -172 -336
-181 -209 -16 -371 127 -394 348 l-6 59 395 0 c241 0 417 4 454 11 122 22 165
70 172 191 8 135 -56 315 -153 425 -89 101 -235 178 -385 202 -85 14 -217 12
-305 -4z m293 -275 c99 -43 173 -154 188 -285 l7 -55 -333 0 -333 0 7 43 c23
149 107 270 216 308 72 26 174 21 248 -11z"
        />
        <path
          d="M43551 76148 c-19 -13 -44 -39 -55 -58 -20 -34 -21 -50 -24 -643 l-3
-609 24 -52 c29 -63 77 -97 147 -104 65 -6 112 12 153 57 52 58 57 98 57 474
1 355 8 438 50 535 28 65 75 107 142 127 121 35 215 6 258 -80 l25 -50 5 -460
c5 -446 6 -461 27 -500 39 -74 89 -105 166 -105 47 0 113 27 139 56 45 51 48
78 48 401 1 332 10 476 36 563 29 99 148 190 248 190 78 0 131 -33 169 -105
21 -39 22 -53 27 -495 5 -438 6 -457 26 -501 12 -25 39 -59 60 -75 33 -26 46
-29 109 -29 63 0 76 3 109 29 76 58 76 51 76 591 0 511 -4 566 -50 655 -66
130 -224 209 -415 210 -142 0 -256 -46 -366 -148 l-72 -67 -16 25 c-82 124
-217 190 -388 190 -145 0 -271 -51 -380 -154 l-63 -58 0 38 c-1 54 -31 116
-71 145 -50 35 -151 39 -198 7z"
        />
        <path
          d="M46045 76156 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-43
77 -152 112 -235 76z"
        />
        <path
          d="M47148 76160 c-285 -45 -468 -191 -468 -373 0 -96 53 -147 151 -147
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 95 -92 107 -181 l9 -61 -36 -10 c-101 -28 -257 -64 -447 -104 -135
-28 -232 -54 -270 -72 -82 -39 -165 -120 -200 -197 -25 -55 -29 -73 -29 -162
0 -92 3 -105 32 -168 81 -171 257 -259 487 -244 155 10 256 47 410 148 45 30
81 48 83 42 12 -34 109 -146 146 -168 75 -43 161 -25 221 45 35 42 34 84 -5
229 l-31 115 -2 335 c-3 356 -8 402 -56 505 -49 104 -154 177 -300 211 -87 20
-363 29 -452 15z m444 -867 c-6 -59 -18 -129 -27 -155 -42 -122 -199 -218
-357 -218 -161 0 -274 151 -208 279 30 61 94 96 228 127 177 40 307 73 333 83
13 5 28 5 33 1 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M68146 76130 c-283 -45 -466 -191 -466 -373 0 -99 51 -147 158 -147
65 0 78 10 140 100 44 65 100 116 157 142 43 19 67 23 175 23 111 0 130 -3
172 -23 38 -19 52 -34 73 -77 15 -29 30 -78 34 -109 l8 -56 -36 -10 c-164 -42
-265 -66 -448 -104 -249 -52 -315 -79 -393 -162 -28 -30 -63 -80 -78 -111 -24
-51 -27 -70 -27 -158 0 -92 3 -106 32 -168 82 -172 257 -258 494 -244 150 9
248 46 412 155 37 25 69 43 70 41 2 -2 25 -35 51 -72 57 -82 121 -127 178
-127 84 0 168 73 168 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 356 -8 402
-56 505 -49 104 -154 177 -300 211 -88 20 -363 29 -454 15z m445 -866 c-13
-134 -31 -190 -81 -247 -80 -91 -239 -144 -357 -119 -180 38 -233 249 -86 342
30 19 85 38 162 56 175 40 306 73 332 83 13 5 28 5 32 1 5 -4 4 -56 -2 -116z"
        />
        <path
          d="M69817 76125 c-259 -49 -444 -219 -523 -480 -26 -83 -28 -103 -28
-265 0 -158 3 -183 25 -255 89 -290 311 -455 634 -472 256 -13 465 55 605 197
77 78 114 156 115 241 0 57 -4 70 -29 103 -35 47 -86 70 -142 63 -58 -6 -83
-28 -154 -134 -100 -151 -178 -198 -325 -198 -108 1 -160 21 -233 89 -86 81
-132 209 -132 371 0 273 127 454 330 472 125 12 227 -35 309 -141 25 -33 67
-82 92 -108 45 -47 48 -48 105 -48 146 0 207 135 128 280 -62 114 -232 234
-389 276 -86 22 -291 27 -388 9z"
        />
        <path
          d="M71426 76130 c-283 -45 -466 -191 -466 -373 0 -99 51 -147 158 -147
65 0 78 10 140 100 44 65 100 116 157 142 43 19 67 23 175 23 111 0 130 -3
172 -23 38 -19 52 -34 73 -77 15 -29 30 -78 34 -109 l8 -56 -36 -10 c-164 -42
-265 -66 -448 -104 -249 -52 -315 -79 -393 -162 -28 -30 -63 -80 -78 -111 -24
-51 -27 -70 -27 -158 0 -92 3 -106 32 -168 82 -172 257 -258 494 -244 150 9
248 46 412 155 37 25 69 43 70 41 2 -2 25 -35 51 -72 57 -82 121 -127 178
-127 84 0 168 73 168 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 356 -8 402
-56 505 -49 104 -154 177 -300 211 -88 20 -363 29 -454 15z m445 -866 c-13
-134 -31 -190 -81 -247 -80 -91 -239 -144 -357 -119 -180 38 -233 249 -86 342
30 19 85 38 162 56 175 40 306 73 332 83 13 5 28 5 32 1 5 -4 4 -56 -2 -116z"
        />
        <path
          d="M74825 76125 c-265 -48 -454 -226 -526 -495 -32 -118 -32 -357 -1
-471 69 -256 237 -421 492 -485 114 -29 357 -27 464 4 146 42 262 113 330 202
39 51 66 117 66 159 0 42 -34 88 -75 101 -71 24 -105 10 -212 -84 -63 -55
-124 -99 -171 -122 -250 -122 -513 28 -539 308 l-6 57 429 3 c469 4 484 6 546
65 98 93 40 392 -107 560 -89 101 -235 178 -385 202 -85 14 -217 12 -305 -4z
m292 -275 c101 -43 174 -153 189 -285 l7 -55 -333 0 -333 0 7 43 c4 23 16 71
28 107 28 85 118 180 194 204 67 22 174 16 241 -14z"
        />
        <path
          d="M37412 74603 c-40 -19 -66 -55 -81 -108 -7 -25 -11 -151 -11 -338 l0
-298 -65 55 c-128 108 -216 144 -375 153 -200 10 -333 -38 -460 -166 -83 -84
-129 -164 -163 -286 -29 -102 -35 -358 -12 -480 66 -353 309 -569 620 -552
157 9 275 61 393 176 29 28 55 51 58 51 2 0 4 -22 4 -48 0 -66 35 -130 86
-160 31 -18 50 -22 97 -19 93 6 142 54 158 157 6 41 9 376 7 905 l-3 840 -23
47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-337 -836 c57 -28 133 -105
163 -163 42 -82 56 -154 56 -279 0 -173 -32 -271 -120 -365 -63 -68 -128 -94
-229 -95 -68 0 -89 4 -137 28 -136 67 -211 221 -210 437 0 294 143 478 362
466 45 -2 81 -11 115 -29z"
        />
        <path
          d="M71122 74603 c-37 -18 -63 -52 -80 -102 -8 -23 -12 -135 -12 -338 l0
-304 -80 65 c-129 106 -202 134 -360 143 -187 9 -326 -37 -446 -149 -79 -75
-124 -147 -165 -269 -30 -88 -33 -105 -37 -264 -3 -135 -1 -188 13 -255 71
-355 311 -564 625 -547 154 8 267 60 390 178 28 27 53 49 54 49 2 0 6 -27 9
-60 3 -34 14 -74 26 -93 27 -43 88 -77 140 -77 96 0 156 56 172 162 6 39 9
385 7 903 l-3 840 -23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-335
-836 c55 -29 132 -106 161 -163 42 -82 56 -154 56 -279 0 -223 -74 -371 -219
-437 -76 -35 -191 -33 -267 5 -135 66 -211 221 -210 432 0 244 90 407 255 461
61 20 164 11 224 -19z"
        />
        <path
          d="M42235 74581 c-99 -46 -134 -153 -82 -251 56 -106 218 -122 300 -31
39 43 53 97 42 161 -18 108 -153 171 -260 121z"
        />
        <path
          d="M75945 74581 c-71 -33 -105 -86 -105 -163 0 -87 58 -158 143 -173
135 -25 241 71 223 203 -8 62 -32 97 -87 126 -56 31 -117 33 -174 7z"
        />
        <path
          d="M31819 74055 c-259 -42 -438 -193 -439 -368 0 -97 51 -147 152 -147
64 0 86 14 129 78 45 66 108 130 149 152 91 47 287 52 373 11 54 -26 91 -89
104 -178 l10 -62 -111 -30 c-61 -17 -187 -46 -281 -66 -261 -54 -306 -66 -362
-92 -79 -36 -157 -113 -195 -191 -31 -62 -33 -74 -33 -167 0 -92 3 -106 32
-168 82 -172 256 -259 493 -244 148 10 257 50 410 153 45 30 75 44 77 37 12
-34 109 -146 146 -168 75 -43 161 -25 221 45 35 42 34 81 -5 228 l-30 117 -3
335 c-2 271 -6 347 -19 397 -48 183 -143 273 -337 318 -102 23 -365 29 -481
10z m473 -862 c-6 -59 -18 -129 -27 -154 -64 -187 -366 -285 -510 -166 -119
98 -90 261 58 321 17 8 124 35 237 61 113 26 212 51 220 55 28 16 32 -8 22
-117z"
        />
        <path
          d="M33522 74056 c-264 -50 -449 -219 -528 -481 -25 -82 -28 -105 -28
-260 -1 -187 10 -246 64 -363 107 -230 312 -354 605 -369 317 -15 576 105 681
316 40 81 41 172 1 224 -39 51 -80 70 -137 65 -63 -6 -88 -27 -160 -135 -100
-151 -178 -198 -325 -198 -111 1 -171 24 -238 93 -94 96 -132 220 -124 402 11
258 132 420 326 437 126 12 228 -34 310 -141 25 -33 67 -82 92 -108 45 -47 48
-48 105 -48 146 0 207 135 128 280 -62 114 -231 234 -389 276 -83 22 -290 27
-383 10z"
        />
        <path
          d="M35099 74055 c-259 -42 -438 -193 -439 -368 0 -97 51 -147 152 -147
64 0 86 14 129 78 45 66 108 130 149 152 91 47 287 52 373 11 54 -26 91 -89
104 -178 l10 -62 -111 -30 c-61 -17 -187 -46 -281 -66 -261 -54 -306 -66 -362
-92 -79 -36 -157 -113 -195 -191 -31 -62 -33 -74 -33 -167 0 -92 3 -106 32
-168 82 -172 256 -259 493 -244 148 10 257 50 410 153 45 30 75 44 77 37 12
-34 109 -146 146 -168 75 -43 161 -25 221 45 35 42 34 81 -5 228 l-30 117 -3
335 c-2 271 -6 347 -19 397 -48 183 -143 273 -337 318 -102 23 -365 29 -481
10z m473 -862 c-6 -59 -18 -129 -27 -154 -64 -187 -366 -285 -510 -166 -119
98 -90 261 58 321 17 8 124 35 237 61 113 26 212 51 220 55 28 16 32 -8 22
-117z"
        />
        <path
          d="M38548 74060 c-282 -48 -475 -223 -550 -500 -31 -118 -32 -356 0
-473 94 -345 372 -525 777 -504 131 7 230 33 337 89 215 112 312 336 170 395
-71 30 -109 16 -219 -81 -126 -111 -211 -155 -314 -163 -211 -16 -373 127
-396 348 l-6 59 393 0 c416 0 497 6 552 44 55 37 70 68 76 149 9 143 -54 321
-153 434 -52 59 -156 132 -231 162 -116 45 -304 63 -436 41z m269 -280 c101
-43 174 -153 189 -285 l7 -55 -332 0 c-376 0 -343 -10 -316 93 33 128 110 224
207 259 70 24 173 20 245 -12z"
        />
        <path
          d="M39741 74048 c-19 -13 -44 -39 -55 -58 -21 -34 -21 -48 -21 -655 l0
-620 25 -45 c43 -77 149 -112 233 -76 49 20 93 77 106 136 7 31 11 181 11 383
1 354 9 441 49 533 42 94 102 133 215 141 96 6 149 -20 186 -92 l25 -49 5
-461 c5 -446 6 -461 27 -500 52 -98 149 -132 248 -86 98 45 104 69 105 441 1
469 16 573 93 657 119 129 288 124 360 -11 22 -40 22 -51 27 -501 5 -450 5
-461 27 -501 44 -82 107 -113 200 -100 59 8 110 47 135 102 18 42 19 68 16
557 -3 504 -3 513 -25 567 -33 81 -79 138 -147 182 -74 47 -137 67 -242 75
-166 12 -298 -34 -418 -147 l-69 -65 -16 25 c-82 124 -217 190 -388 190 -144
0 -271 -51 -379 -152 l-61 -58 -6 50 c-13 105 -63 152 -167 158 -55 3 -70 0
-99 -20z"
        />
        <path
          d="M42232 74053 c-43 -21 -79 -65 -92 -113 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-44
79 -156 114 -238 73z"
        />
        <path
          d="M43365 74064 c-308 -47 -495 -189 -495 -377 0 -96 53 -147 151 -147
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 374 10 58 -28 98 -102
100 -187 l1 -56 -90 -24 c-49 -13 -204 -49 -344 -79 -293 -62 -351 -86 -431
-170 -28 -30 -63 -80 -78 -111 -24 -51 -27 -70 -27 -158 0 -92 3 -106 32 -168
82 -172 257 -258 494 -244 150 9 248 46 412 155 37 25 69 43 70 41 2 -2 25
-35 51 -72 56 -80 122 -127 175 -127 49 1 111 31 144 69 36 43 35 84 -4 230
l-31 115 -2 335 c-3 272 -6 348 -19 398 -35 134 -90 210 -186 261 -101 54
-194 73 -381 77 -91 2 -178 1 -195 -1z m416 -870 c-13 -133 -31 -190 -81 -246
-85 -98 -263 -152 -381 -117 -119 36 -183 174 -126 274 38 66 89 89 322 144
116 27 221 54 235 59 14 6 29 6 33 2 5 -4 4 -56 -2 -116z"
        />
        <path
          d="M44978 74060 c-281 -45 -468 -191 -468 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 373 11 55 -26 91 -89
104 -179 l10 -62 -36 -9 c-149 -39 -274 -69 -452 -106 -126 -26 -228 -53 -262
-70 -83 -39 -160 -114 -199 -193 -31 -62 -33 -74 -33 -167 0 -88 3 -107 27
-158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 188 -1 291 32 460 146
41 28 77 49 78 47 2 -2 25 -35 51 -72 56 -80 122 -127 175 -127 87 1 171 73
171 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 272 -6 348 -19 398 -48 183
-145 274 -337 318 -87 20 -363 29 -452 15z m452 -767 c-1 -66 -21 -211 -35
-252 -47 -143 -249 -247 -412 -213 -133 28 -209 162 -153 271 32 63 93 96 248
131 76 18 175 42 222 55 108 29 130 30 130 8z"
        />
        <path
          d="M46655 74056 c-270 -53 -451 -217 -531 -481 -25 -82 -28 -105 -28
-260 -1 -187 10 -246 63 -362 109 -232 313 -356 606 -370 318 -14 576 105 681
316 22 44 28 70 29 122 0 57 -4 70 -29 103 -35 47 -86 70 -142 63 -58 -6 -83
-28 -154 -134 -100 -151 -178 -198 -325 -198 -111 1 -171 24 -238 93 -84 86
-127 208 -127 363 0 109 15 188 50 265 73 161 224 240 392 205 74 -16 139 -60
197 -135 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 146 0 207 135 128 280
-62 114 -232 234 -389 276 -83 21 -292 27 -380 10z"
        />
        <path
          d="M48258 74060 c-281 -45 -468 -191 -468 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 373 11 55 -26 91 -89
104 -179 l10 -62 -36 -9 c-149 -39 -274 -69 -452 -106 -126 -26 -228 -53 -262
-70 -83 -39 -160 -114 -199 -193 -31 -62 -33 -74 -33 -167 0 -88 3 -107 27
-158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 188 -1 291 32 460 146
41 28 77 49 78 47 2 -2 25 -35 51 -72 56 -80 122 -127 175 -127 87 1 171 73
171 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 272 -6 348 -19 398 -48 183
-145 274 -337 318 -87 20 -363 29 -452 15z m452 -767 c-1 -66 -21 -211 -35
-252 -47 -143 -249 -247 -412 -213 -133 28 -209 162 -153 271 32 63 93 96 248
131 76 18 175 42 222 55 108 29 130 30 130 8z"
        />
        <path
          d="M65556 74060 c-279 -44 -466 -192 -466 -368 0 -99 53 -152 151 -152
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 93 -89 106 -179 l10 -61 -91 -26 c-50 -14 -165 -41 -256 -61 -325
-70 -356 -78 -408 -102 -79 -37 -163 -120 -198 -195 -26 -56 -29 -74 -29 -163
0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 97 -64 153 -79 301 -80 188 -1
288 31 463 148 40 27 74 47 75 45 2 -2 25 -35 51 -72 56 -80 122 -127 175
-127 87 1 171 73 171 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 358 -8 402
-58 508 -28 60 -99 132 -158 161 -94 45 -194 63 -370 67 -91 2 -192 0 -224 -5z
m454 -767 c-1 -66 -21 -211 -35 -252 -62 -188 -365 -288 -510 -168 -102 84
-97 228 11 296 32 21 88 40 183 62 75 17 177 42 226 54 112 29 125 30 125 8z"
        />
        <path
          d="M67227 74055 c-299 -57 -501 -277 -548 -597 -16 -112 -6 -302 21
-398 33 -115 85 -205 165 -285 121 -121 268 -181 470 -192 256 -13 465 55 605
197 77 78 114 156 115 241 0 57 -4 70 -29 103 -35 47 -86 70 -142 63 -58 -6
-83 -28 -154 -134 -100 -151 -178 -198 -325 -198 -111 1 -171 24 -238 93 -85
87 -127 208 -127 367 0 273 127 454 330 472 125 12 227 -35 309 -141 25 -33
67 -82 92 -108 45 -47 48 -48 105 -48 146 0 207 135 128 280 -62 114 -232 234
-389 276 -86 22 -291 27 -388 9z"
        />
        <path
          d="M68836 74060 c-279 -44 -466 -192 -466 -368 0 -99 53 -152 151 -152
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 93 -89 106 -179 l10 -61 -91 -26 c-50 -14 -165 -41 -256 -61 -325
-70 -356 -78 -408 -102 -79 -37 -163 -120 -198 -195 -26 -56 -29 -74 -29 -163
0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 97 -64 153 -79 301 -80 188 -1
288 31 463 148 40 27 74 47 75 45 2 -2 25 -35 51 -72 56 -80 122 -127 175
-127 87 1 171 73 171 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 358 -8 402
-58 508 -28 60 -99 132 -158 161 -94 45 -194 63 -370 67 -91 2 -192 0 -224 -5z
m454 -767 c-1 -66 -21 -211 -35 -252 -62 -188 -365 -288 -510 -168 -102 84
-97 228 11 296 32 21 88 40 183 62 75 17 177 42 226 54 112 29 125 30 125 8z"
        />
        <path
          d="M72235 74055 c-265 -48 -454 -226 -526 -495 -32 -118 -32 -357 -1
-471 68 -251 231 -416 476 -481 102 -27 298 -34 406 -15 247 43 445 194 467
356 11 80 -50 137 -138 129 -43 -4 -57 -13 -137 -84 -108 -96 -196 -149 -271
-165 -154 -32 -315 33 -388 158 -33 56 -63 154 -63 204 l0 39 394 0 c224 0
416 4 447 10 128 24 170 70 177 192 8 135 -56 315 -153 425 -89 101 -235 178
-385 202 -85 14 -217 12 -305 -4z m293 -275 c99 -43 173 -154 188 -285 l7 -55
-333 0 -333 0 7 43 c23 149 107 270 216 308 72 26 174 21 248 -11z"
        />
        <path
          d="M73451 74048 c-19 -13 -44 -39 -55 -58 -21 -34 -21 -48 -21 -655 l0
-620 25 -45 c43 -77 149 -112 233 -76 49 20 93 77 106 136 7 31 11 181 11 383
1 355 8 438 50 535 28 65 75 107 142 127 121 35 215 6 258 -80 l25 -50 5 -455
c6 -492 6 -497 63 -559 48 -52 139 -66 212 -32 98 45 104 69 105 441 1 334 11
486 36 564 23 67 101 146 168 169 114 39 197 9 249 -88 21 -39 22 -53 27 -495
5 -438 6 -457 26 -501 71 -153 297 -141 344 19 8 25 10 195 8 542 -3 495 -4
506 -26 560 -32 81 -78 138 -146 182 -88 57 -150 73 -286 73 -170 0 -265 -38
-383 -153 l-57 -56 -17 24 c-58 87 -170 161 -272 180 -186 35 -361 -16 -498
-144 l-63 -58 0 38 c-1 54 -31 116 -71 145 -50 35 -151 39 -198 7z"
        />
        <path
          d="M75945 74056 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-43
77 -152 112 -235 76z"
        />
        <path
          d="M77019 74055 c-259 -42 -438 -193 -439 -368 0 -97 51 -147 152 -147
64 0 86 14 129 78 45 66 108 130 149 152 91 47 287 52 373 11 54 -26 91 -89
104 -178 l10 -62 -111 -30 c-61 -17 -187 -46 -281 -66 -261 -54 -306 -66 -362
-92 -79 -36 -157 -113 -195 -191 -31 -62 -33 -74 -33 -167 0 -92 3 -106 32
-168 82 -172 256 -259 493 -244 148 10 257 50 410 153 45 30 75 44 77 37 12
-34 109 -146 146 -168 75 -43 161 -25 221 45 35 42 34 81 -5 228 l-30 117 -3
335 c-2 271 -6 347 -19 397 -48 183 -143 273 -337 318 -102 23 -365 29 -481
10z m473 -862 c-6 -59 -18 -129 -27 -154 -64 -187 -366 -285 -510 -166 -119
98 -90 261 58 321 17 8 124 35 237 61 113 26 212 51 220 55 28 16 32 -8 22
-117z"
        />
        <path
          d="M34572 72523 c-40 -19 -66 -55 -81 -108 -7 -25 -11 -151 -11 -337 l0
-298 -80 65 c-130 105 -203 133 -360 142 -199 10 -333 -38 -460 -166 -57 -56
-85 -95 -112 -151 -63 -133 -73 -190 -73 -410 0 -174 3 -204 23 -274 44 -153
133 -295 229 -368 234 -176 547 -154 766 56 32 31 60 56 63 56 2 0 4 -22 4
-48 0 -101 75 -181 168 -182 97 0 156 56 173 162 6 39 9 385 7 903 l-3 840
-23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-365 -825 c105 -39 189
-140 229 -273 25 -83 25 -277 0 -360 -38 -128 -105 -213 -201 -257 -76 -35
-191 -33 -267 5 -136 67 -211 221 -210 437 0 240 92 405 255 456 62 19 129 17
194 -8z"
        />
        <path
          d="M47702 72523 c-40 -19 -66 -55 -81 -108 -7 -25 -11 -151 -11 -338 l0
-298 -82 67 c-45 37 -106 78 -134 91 -158 72 -386 69 -542 -8 -78 -38 -180
-132 -226 -208 -20 -31 -50 -100 -67 -152 -30 -88 -33 -105 -37 -264 -3 -135
-1 -188 13 -255 71 -355 311 -564 625 -547 152 8 268 61 389 177 29 27 54 50
57 50 2 0 4 -22 4 -48 0 -69 34 -130 90 -159 112 -60 226 -1 250 130 7 35 10
354 8 904 l-3 848 -23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-345
-833 c58 -25 139 -103 171 -166 42 -82 56 -153 56 -279 0 -173 -32 -271 -120
-365 -63 -68 -128 -94 -229 -95 -68 0 -89 4 -137 28 -136 67 -211 221 -210
437 0 240 91 402 255 456 57 19 150 12 214 -16z"
        />
        <path
          d="M39385 72501 c-71 -33 -105 -86 -105 -163 0 -87 58 -158 143 -173
135 -25 241 71 223 203 -8 62 -32 97 -87 126 -56 31 -117 33 -174 7z"
        />
        <path
          d="M69332 72493 c-37 -18 -63 -52 -80 -102 -8 -23 -12 -135 -12 -338 l0
-305 -50 44 c-136 116 -232 156 -395 165 -328 17 -567 -177 -630 -510 -20
-106 -20 -334 1 -435 70 -345 313 -556 619 -539 157 9 271 59 395 176 30 28
55 51 56 51 1 0 4 -27 7 -60 9 -104 74 -170 167 -170 95 0 155 57 171 162 6
39 9 385 7 903 l-3 840 -23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z
m-345 -833 c58 -25 139 -103 171 -166 42 -83 56 -153 56 -279 0 -224 -74 -371
-219 -437 -76 -35 -191 -33 -267 5 -136 67 -211 221 -210 437 0 240 91 402
255 456 57 19 150 12 214 -16z"
        />
        <path
          d="M74145 72471 c-71 -33 -105 -86 -105 -164 1 -119 96 -192 226 -172
34 5 55 16 88 48 48 47 63 95 51 167 -18 109 -152 171 -260 121z"
        />
        <path
          d="M29035 71984 c-305 -45 -495 -190 -495 -377 0 -98 52 -147 157 -147
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 110 0 131 -3 171
-23 63 -30 100 -98 102 -184 l2 -61 -90 -24 c-49 -13 -204 -48 -343 -78 -284
-61 -335 -81 -421 -161 -218 -202 -128 -564 166 -665 58 -21 87 -24 198 -24
96 1 146 5 190 19 78 23 199 84 277 138 38 26 63 38 65 31 12 -34 109 -146
146 -168 76 -44 166 -24 221 48 34 45 33 83 -5 226 l-31 115 -2 335 c-3 358
-8 402 -58 508 -28 60 -99 131 -159 161 -94 45 -192 63 -369 67 -91 2 -178 1
-195 -1z m420 -841 c-16 -218 -76 -314 -232 -373 -119 -45 -236 -37 -308 23
-102 84 -97 228 11 296 31 20 85 39 163 57 64 14 163 39 221 54 162 42 152 46
145 -57z"
        />
        <path
          d="M30682 71976 c-264 -51 -449 -218 -528 -481 -25 -82 -28 -105 -28
-260 -1 -149 2 -180 22 -250 54 -185 167 -327 322 -403 112 -55 181 -72 325
-79 318 -15 576 105 681 316 22 44 28 70 29 122 0 57 -4 70 -29 103 -35 47
-86 70 -142 63 -58 -6 -83 -28 -154 -134 -69 -104 -102 -135 -180 -172 -75
-36 -209 -37 -286 -2 -139 61 -224 226 -224 436 0 273 127 454 330 472 125 12
227 -35 309 -141 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 146 0 207 135
128 280 -62 114 -232 234 -389 276 -84 22 -291 27 -383 10z"
        />
        <path
          d="M32315 71984 c-305 -45 -495 -190 -495 -377 0 -98 52 -147 157 -147
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 110 0 131 -3 171
-23 63 -30 100 -98 102 -184 l2 -61 -90 -24 c-49 -13 -204 -48 -343 -78 -284
-61 -335 -81 -421 -161 -218 -202 -128 -564 166 -665 58 -21 87 -24 198 -24
96 1 146 5 190 19 78 23 199 84 277 138 38 26 63 38 65 31 12 -34 109 -146
146 -168 76 -44 166 -24 221 48 34 45 33 83 -5 226 l-31 115 -2 335 c-3 358
-8 402 -58 508 -28 60 -99 131 -159 161 -94 45 -192 63 -369 67 -91 2 -178 1
-195 -1z m420 -841 c-16 -218 -76 -314 -232 -373 -119 -45 -236 -37 -308 23
-102 84 -97 228 11 296 31 20 85 39 163 57 64 14 163 39 221 54 162 42 152 46
145 -57z"
        />
        <path
          d="M35708 71980 c-283 -48 -478 -226 -550 -502 -31 -116 -31 -357 0
-469 69 -255 237 -420 492 -485 102 -26 355 -27 450 -1 215 57 388 204 407
346 11 81 -50 137 -139 129 -42 -5 -57 -13 -125 -74 -104 -93 -143 -120 -211
-152 -48 -22 -71 -26 -152 -26 -84 -1 -102 2 -157 28 -68 31 -129 89 -162 155
-26 49 -51 144 -51 188 l0 33 392 0 c413 0 495 6 550 44 55 37 70 68 76 149 9
143 -54 321 -153 434 -53 60 -157 132 -235 163 -113 45 -300 62 -432 40z m269
-280 c101 -43 174 -153 189 -285 l7 -55 -333 0 -333 0 7 43 c23 149 107 270
216 308 72 26 174 21 247 -11z"
        />
        <path
          d="M36914 71976 c-39 -17 -71 -58 -84 -107 -7 -25 -10 -247 -8 -636 l3
-598 25 -45 c43 -77 149 -112 233 -76 49 20 93 77 106 136 7 31 11 181 11 383
1 354 9 441 49 533 42 94 102 133 215 141 97 6 150 -20 186 -92 l25 -50 5
-460 c5 -446 6 -461 27 -500 39 -74 89 -105 166 -105 47 0 113 27 139 56 45
51 48 78 48 401 1 336 10 489 36 567 23 70 91 140 160 166 117 43 204 15 257
-85 21 -39 22 -53 27 -495 5 -438 6 -457 26 -501 12 -25 39 -59 60 -75 33 -26
46 -29 109 -29 63 0 76 3 109 29 76 58 76 51 76 592 0 283 -4 503 -10 534 -42
219 -233 346 -495 327 -139 -10 -227 -50 -333 -151 l-63 -60 -16 25 c-59 88
-167 159 -273 179 -184 35 -360 -16 -497 -144 l-63 -58 0 38 c-1 54 -31 116
-71 145 -41 29 -137 37 -185 15z"
        />
        <path
          d="M39385 71976 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-43
77 -152 112 -235 76z"
        />
        <path
          d="M40459 71975 c-259 -42 -438 -193 -439 -368 0 -97 51 -147 152 -147
64 0 86 14 129 78 45 66 108 130 149 152 91 47 287 52 373 11 54 -26 91 -89
104 -178 l10 -62 -111 -30 c-61 -17 -187 -46 -281 -66 -261 -54 -306 -66 -362
-92 -79 -36 -157 -113 -195 -191 -31 -62 -33 -74 -33 -167 0 -92 3 -106 32
-168 82 -172 256 -259 493 -244 148 10 257 50 410 153 45 30 75 44 77 37 12
-34 109 -146 146 -168 75 -43 161 -25 221 45 35 42 34 81 -5 228 l-30 117 -3
335 c-2 271 -6 347 -19 397 -48 183 -143 273 -337 318 -102 23 -365 29 -481
10z m473 -862 c-6 -59 -18 -129 -27 -154 -64 -187 -366 -285 -510 -166 -119
98 -90 261 58 321 17 8 124 35 237 61 113 26 212 51 220 55 28 16 32 -8 22
-117z"
        />
        <path
          d="M42155 71984 c-305 -45 -495 -190 -495 -377 0 -98 52 -147 157 -147
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 110 0 131 -3 171
-23 63 -30 100 -98 102 -184 l2 -61 -90 -24 c-49 -13 -204 -48 -343 -78 -284
-61 -335 -81 -421 -161 -218 -202 -128 -564 166 -665 58 -21 87 -24 198 -24
96 1 146 5 190 19 78 23 199 84 277 138 38 26 63 38 65 31 12 -34 109 -146
146 -168 76 -44 166 -24 221 48 34 45 33 83 -5 226 l-31 115 -2 335 c-3 358
-8 402 -58 508 -28 60 -99 131 -159 161 -94 45 -192 63 -369 67 -91 2 -178 1
-195 -1z m420 -841 c-16 -218 -76 -314 -232 -373 -119 -45 -236 -37 -308 23
-102 84 -97 228 11 296 31 20 85 39 163 57 64 14 163 39 221 54 162 42 152 46
145 -57z"
        />
        <path
          d="M43807 71975 c-298 -57 -497 -273 -548 -596 -17 -111 -6 -305 23
-404 52 -178 167 -319 319 -394 112 -55 181 -71 324 -78 319 -14 576 105 681
316 40 81 41 172 1 224 -39 51 -80 70 -137 65 -63 -6 -88 -27 -160 -135 -69
-104 -102 -135 -180 -172 -75 -36 -209 -37 -286 -2 -138 61 -223 226 -224 432
0 164 40 288 125 377 78 84 195 118 317 93 74 -16 139 -60 197 -135 25 -33 67
-82 92 -108 45 -47 48 -48 105 -48 46 0 67 5 95 24 42 29 69 79 69 130 0 117
-122 267 -280 344 -47 23 -112 49 -145 58 -86 22 -291 27 -388 9z"
        />
        <path
          d="M45445 71984 c-308 -47 -495 -189 -495 -377 0 -96 53 -147 151 -147
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 374 10 58 -28 98 -102
100 -187 l1 -56 -90 -24 c-49 -13 -204 -49 -344 -79 -293 -62 -351 -86 -431
-170 -28 -30 -63 -80 -78 -111 -24 -51 -27 -70 -27 -158 0 -92 3 -106 32 -168
82 -172 257 -258 494 -244 150 9 248 46 412 155 37 25 69 43 70 41 2 -2 25
-35 51 -72 56 -80 122 -127 175 -127 49 1 111 31 144 69 36 43 35 84 -4 230
l-31 115 -2 335 c-3 272 -6 348 -19 398 -35 134 -90 210 -186 261 -101 54
-194 73 -381 77 -91 2 -178 1 -195 -1z m416 -870 c-13 -133 -31 -190 -81 -246
-85 -98 -263 -152 -381 -117 -119 36 -183 174 -126 274 38 66 89 89 322 144
116 27 221 54 235 59 14 6 29 6 33 2 5 -4 4 -56 -2 -116z"
        />
        <path
          d="M48838 71980 c-230 -39 -400 -161 -491 -352 -67 -139 -82 -214 -81
-393 1 -176 20 -260 84 -383 127 -245 376 -367 715 -349 132 7 230 33 337 89
215 111 311 336 170 395 -71 30 -110 16 -218 -80 -62 -55 -125 -100 -173 -124
-72 -36 -81 -38 -171 -38 -84 0 -102 3 -157 29 -68 31 -129 89 -161 153 -28
54 -52 140 -52 184 l0 38 428 3 c467 4 482 6 544 65 98 93 40 392 -107 560
-89 101 -235 178 -385 202 -74 12 -212 12 -282 1z m269 -280 c101 -43 174
-153 189 -285 l7 -55 -332 0 c-377 0 -343 -10 -316 95 9 33 26 79 38 104 30
59 117 137 175 156 65 21 173 14 239 -15z"
        />
        <path
          d="M50031 71968 c-19 -13 -44 -39 -55 -58 -21 -34 -21 -48 -21 -655 l0
-620 25 -44 c44 -78 149 -113 233 -77 49 20 93 77 106 136 7 31 11 181 11 383
1 354 9 441 49 533 42 94 102 133 215 141 96 6 149 -20 186 -92 l25 -49 5
-461 c5 -446 6 -461 27 -500 52 -98 149 -132 248 -86 98 45 104 69 105 441 1
331 11 486 35 561 24 70 93 143 161 168 117 44 204 16 257 -84 21 -39 22 -53
27 -495 5 -438 6 -457 26 -501 12 -25 39 -59 60 -75 33 -26 45 -29 109 -29 64
0 76 3 109 29 21 16 47 48 58 73 18 41 19 67 16 551 -4 562 -4 560 -71 659
-39 57 -136 126 -210 148 -29 9 -95 19 -147 22 -163 10 -279 -32 -401 -145
l-72 -67 -15 25 c-27 42 -109 118 -151 140 -58 29 -155 50 -236 50 -146 0
-272 -51 -381 -152 l-61 -58 -6 50 c-13 105 -63 152 -167 158 -55 3 -70 0 -99
-20z"
        />
        <path
          d="M63785 71954 c-308 -47 -495 -189 -495 -377 0 -96 53 -147 151 -147
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 93 -89 106 -179 8 -54 7 -63 -6 -67 -68 -21 -249 -64 -429 -101
-118 -25 -238 -54 -267 -64 -105 -37 -197 -120 -244 -220 -22 -49 -26 -69 -26
-156 0 -94 2 -105 34 -170 85 -172 255 -257 485 -242 50 3 118 13 151 22 73
19 196 80 275 136 33 23 62 40 63 38 2 -2 25 -35 51 -72 82 -117 167 -152 255
-107 58 29 91 74 91 121 0 22 -14 93 -31 157 l-30 117 -3 335 c-3 357 -8 401
-58 507 -28 60 -99 132 -158 161 -94 45 -194 63 -370 67 -91 2 -178 1 -195 -1z
m425 -771 c-1 -66 -21 -211 -35 -252 -50 -151 -273 -258 -436 -210 -117 36
-181 168 -129 270 32 63 91 93 249 130 75 17 174 41 221 54 108 29 130 30 130
8z"
        />
        <path
          d="M65480 71954 c-152 -27 -254 -68 -347 -140 -164 -128 -250 -315 -260
-569 -9 -213 24 -350 118 -490 64 -95 133 -156 234 -205 108 -54 178 -70 320
-77 319 -14 576 105 681 316 40 81 41 172 1 224 -39 51 -80 70 -137 65 -63 -6
-88 -27 -160 -135 -69 -104 -102 -135 -180 -172 -75 -36 -209 -37 -286 -2
-100 44 -178 150 -210 287 -19 82 -14 272 9 345 66 206 227 309 419 270 74
-16 139 -60 197 -135 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 146 0 207
135 128 280 -62 114 -231 234 -389 276 -58 15 -282 28 -335 18z"
        />
        <path
          d="M67065 71954 c-308 -47 -495 -189 -495 -377 0 -96 53 -147 151 -147
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 93 -89 106 -179 8 -54 7 -63 -6 -67 -68 -21 -249 -64 -429 -101
-118 -25 -238 -54 -267 -64 -105 -37 -197 -120 -244 -220 -22 -49 -26 -69 -26
-156 0 -94 2 -105 34 -170 85 -172 255 -257 485 -242 50 3 118 13 151 22 73
19 196 80 275 136 33 23 62 40 63 38 2 -2 25 -35 51 -72 82 -117 167 -152 255
-107 58 29 91 74 91 121 0 22 -14 93 -31 157 l-30 117 -3 335 c-3 357 -8 401
-58 507 -28 60 -99 132 -158 161 -94 45 -194 63 -370 67 -91 2 -178 1 -195 -1z
m425 -771 c-1 -66 -21 -211 -35 -252 -50 -151 -273 -258 -436 -210 -117 36
-181 168 -129 270 32 63 91 93 249 130 75 17 174 41 221 54 108 29 130 30 130
8z"
        />
        <path
          d="M70458 71950 c-282 -48 -475 -223 -549 -500 -32 -119 -32 -357 -1
-473 94 -344 373 -525 777 -504 231 12 429 106 524 248 71 106 64 202 -16 236
-72 30 -110 16 -220 -81 -61 -53 -126 -100 -173 -123 -71 -36 -80 -38 -170
-38 -84 0 -102 3 -157 29 -68 31 -129 89 -161 153 -28 54 -52 140 -52 185 l0
38 394 0 c403 0 468 5 541 40 65 32 80 67 80 190 0 116 -14 170 -71 280 -86
165 -247 280 -444 316 -81 14 -227 16 -302 4z m270 -280 c99 -43 173 -154 188
-285 l7 -55 -332 0 c-376 0 -343 -10 -316 93 8 32 26 79 39 106 32 62 115 137
175 156 64 21 172 14 239 -15z"
        />
        <path
          d="M71651 71938 c-19 -13 -44 -39 -55 -58 -21 -34 -21 -48 -21 -655 l0
-620 25 -44 c44 -78 149 -113 233 -77 49 20 93 77 106 136 7 31 11 181 11 383
1 355 8 438 50 535 28 65 75 107 142 127 120 35 214 7 258 -80 l25 -49 5 -456
c6 -492 6 -497 63 -559 63 -68 206 -65 269 5 45 51 48 78 48 401 1 473 14 570
92 658 65 73 143 104 233 91 53 -8 97 -43 128 -100 22 -40 22 -51 27 -496 5
-438 6 -457 26 -501 12 -25 39 -59 60 -75 33 -26 46 -29 109 -29 64 0 76 3
109 29 21 16 47 49 58 73 18 42 19 67 16 556 -3 503 -4 513 -26 567 -32 81
-78 138 -146 182 -77 50 -139 68 -256 75 -163 10 -280 -33 -403 -147 l-70 -65
-15 25 c-54 86 -166 160 -272 180 -183 35 -360 -15 -493 -140 l-64 -60 -6 50
c-13 105 -63 152 -167 158 -55 3 -70 0 -99 -20z"
        />
        <path
          d="M74145 71946 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-43
77 -152 112 -235 76z"
        />
        <path
          d="M75248 71950 c-283 -45 -468 -191 -468 -370 0 -101 52 -150 158 -150
65 0 78 10 140 100 44 65 100 116 157 142 43 19 67 23 175 23 156 0 202 -17
243 -93 20 -38 52 -172 41 -172 -2 0 -52 -13 -111 -29 -60 -16 -184 -46 -278
-65 -266 -56 -306 -67 -365 -94 -78 -36 -161 -119 -196 -195 -25 -55 -29 -73
-29 -162 0 -92 3 -105 32 -168 81 -170 258 -259 487 -244 154 10 263 50 416
153 45 30 75 44 77 37 12 -34 109 -146 146 -168 76 -44 166 -24 221 48 34 45
33 83 -5 226 l-31 115 -2 335 c-3 358 -8 402 -58 508 -28 60 -99 132 -158 161
-94 45 -194 63 -370 67 -91 2 -191 0 -222 -5z m444 -867 c-6 -59 -18 -129 -27
-155 -42 -122 -199 -218 -357 -218 -163 0 -274 151 -208 281 31 60 92 93 229
125 175 40 306 73 332 83 13 5 28 5 33 1 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M33660 70473 c-34 -13 -69 -45 -86 -80 -17 -34 -20 -71 -24 -355 l-5
-316 -70 60 c-122 104 -244 148 -410 148 -170 0 -300 -53 -415 -169 -82 -82
-125 -157 -162 -279 -29 -98 -37 -376 -13 -493 71 -353 312 -564 625 -546 155
9 265 59 390 176 30 28 55 51 56 51 1 0 4 -27 7 -60 7 -77 35 -122 93 -150 79
-39 165 -18 216 51 36 48 39 132 36 994 l-3 840 -23 47 c-17 35 -34 52 -66 67
-40 20 -112 27 -146 14z m-355 -846 c57 -28 133 -105 163 -163 42 -83 56 -154
56 -279 0 -173 -33 -272 -120 -365 -63 -68 -128 -94 -229 -95 -68 0 -89 4
-137 28 -135 66 -211 221 -210 432 0 298 142 483 362 471 45 -2 81 -11 115
-29z"
        />
        <path
          d="M38454 70441 c-131 -60 -140 -252 -14 -318 111 -59 256 2 275 116 26
157 -117 267 -261 202z"
        />
        <path
          d="M48442 70443 c-40 -19 -66 -55 -81 -108 -7 -25 -11 -151 -11 -338 l0
-299 -50 44 c-137 117 -229 156 -390 165 -126 6 -223 -12 -320 -58 -93 -46
-204 -160 -251 -259 -64 -133 -74 -190 -74 -410 1 -169 4 -205 22 -272 90
-320 319 -501 613 -485 152 8 268 61 389 177 29 27 54 50 55 50 2 0 6 -27 9
-60 7 -77 35 -122 93 -150 79 -39 165 -18 216 51 36 48 39 132 36 994 l-3 840
-23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-365 -825 c105 -39 189
-140 229 -273 25 -83 25 -277 0 -360 -37 -125 -108 -215 -199 -256 -79 -35
-192 -34 -269 4 -135 66 -211 221 -210 432 0 244 91 409 255 461 62 19 129 17
194 -8z"
        />
        <path
          d="M53255 70422 c-71 -34 -105 -87 -105 -162 0 -62 26 -115 72 -147 36
-24 51 -28 113 -28 63 0 76 3 108 28 58 44 72 72 72 147 0 74 -14 103 -69 145
-48 36 -134 44 -191 17z"
        />
        <path
          d="M68392 70393 c-37 -18 -63 -52 -80 -102 -8 -23 -12 -135 -12 -338 l0
-304 -72 61 c-124 104 -248 150 -408 150 -309 0 -535 -195 -595 -513 -20 -105
-20 -321 1 -428 66 -349 310 -563 624 -546 154 8 260 56 390 177 30 27 55 50
57 50 2 0 3 -22 3 -48 0 -71 34 -131 90 -159 114 -59 226 0 250 130 7 35 10
354 8 904 l-3 848 -23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-365
-825 c105 -39 189 -140 229 -273 25 -83 25 -277 0 -360 -38 -128 -105 -213
-201 -257 -76 -35 -191 -33 -267 5 -136 67 -211 221 -210 437 0 240 92 405
255 456 62 19 129 17 194 -8z"
        />
        <path
          d="M73215 70371 c-94 -43 -130 -139 -89 -240 31 -78 145 -122 237 -92
50 17 103 79 112 130 26 157 -117 268 -260 202z"
        />
        <path
          d="M28068 69920 c-281 -45 -468 -191 -468 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 374 10 37 -17 50 -32
72 -77 15 -31 30 -79 32 -108 l4 -53 -68 -20 c-86 -25 -245 -62 -436 -101 -82
-17 -172 -39 -202 -50 -106 -37 -198 -119 -245 -220 -22 -49 -26 -69 -26 -156
0 -88 3 -107 27 -158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 186 -1
289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72 82 -117 167 -152 255
-107 58 29 91 74 91 121 0 22 -14 93 -31 157 l-30 117 -3 335 c-3 357 -8 401
-58 507 -29 62 -100 132 -162 162 -98 45 -190 62 -366 66 -91 2 -191 0 -222
-5z m447 -837 c-13 -174 -46 -254 -132 -319 -87 -66 -235 -100 -327 -74 -123
34 -189 166 -136 271 31 60 92 93 229 125 64 15 166 39 226 54 160 41 148 46
140 -57z"
        />
        <path
          d="M29737 69915 c-259 -49 -444 -219 -523 -480 -25 -82 -28 -105 -28
-260 -1 -149 2 -180 22 -250 54 -185 167 -327 322 -403 112 -55 181 -72 325
-79 203 -9 365 30 507 124 127 83 202 199 203 314 0 57 -4 70 -29 103 -35 47
-86 70 -142 63 -58 -6 -83 -28 -154 -134 -100 -151 -178 -198 -325 -198 -111
1 -171 24 -238 93 -94 96 -132 220 -124 402 11 259 131 420 326 437 126 12
228 -34 310 -141 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 146 0 207 135
128 280 -62 114 -232 234 -389 276 -86 22 -291 27 -388 9z"
        />
        <path
          d="M31348 69920 c-281 -45 -468 -191 -468 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 374 10 37 -17 50 -32
72 -77 15 -31 30 -79 32 -108 l4 -53 -68 -20 c-86 -25 -245 -62 -436 -101 -82
-17 -172 -39 -202 -50 -106 -37 -198 -119 -245 -220 -22 -49 -26 -69 -26 -156
0 -88 3 -107 27 -158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 186 -1
289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72 82 -117 167 -152 255
-107 58 29 91 74 91 121 0 22 -14 93 -31 157 l-30 117 -3 335 c-3 357 -8 401
-58 507 -29 62 -100 132 -162 162 -98 45 -190 62 -366 66 -91 2 -191 0 -222
-5z m447 -837 c-13 -174 -46 -254 -132 -319 -87 -66 -235 -100 -327 -74 -123
34 -189 166 -136 271 31 60 92 93 229 125 64 15 166 39 226 54 160 41 148 46
140 -57z"
        />
        <path
          d="M34745 69915 c-262 -47 -455 -227 -527 -492 -30 -112 -31 -363 0
-474 69 -256 237 -420 492 -485 114 -29 357 -27 464 4 158 45 279 124 345 223
71 106 64 202 -16 236 -68 28 -113 14 -195 -59 -150 -133 -230 -176 -343 -185
-206 -16 -369 129 -392 347 l-6 59 429 4 c462 3 479 5 538 57 59 52 72 163 36
304 -62 245 -248 416 -500 462 -93 16 -232 16 -325 -1z m292 -275 c101 -43
174 -153 189 -285 l7 -55 -333 0 -333 0 7 43 c3 23 16 71 28 107 30 86 119
180 194 204 67 22 174 16 241 -14z"
        />
        <path
          d="M35961 69908 c-19 -13 -44 -39 -55 -58 -20 -34 -21 -50 -24 -645 l-3
-610 28 -55 c22 -43 38 -60 73 -77 115 -59 242 1 269 127 7 31 11 181 11 383
1 354 9 441 49 533 42 94 102 133 215 141 96 6 149 -20 186 -92 l25 -49 5
-456 c6 -492 6 -497 63 -559 48 -52 139 -66 212 -32 98 45 104 69 105 441 1
469 16 573 93 657 119 129 288 124 360 -11 22 -40 22 -51 27 -496 5 -438 6
-457 26 -501 71 -153 297 -141 344 19 8 25 10 194 8 537 -4 554 -4 553 -71
652 -39 57 -137 126 -210 148 -75 23 -213 30 -289 16 -93 -18 -183 -67 -262
-141 l-69 -65 -16 25 c-82 124 -217 190 -388 190 -144 0 -270 -51 -376 -150
l-64 -60 -6 50 c-13 105 -63 152 -167 158 -55 3 -70 0 -99 -20z"
        />
        <path
          d="M38455 69916 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 44 c-44
78 -152 113 -235 77z"
        />
        <path
          d="M39555 69920 c-276 -42 -465 -193 -465 -370 0 -99 52 -150 152 -150
64 0 86 14 129 78 45 66 108 130 149 152 91 47 287 52 373 11 60 -29 98 -98
100 -183 l2 -61 -70 -19 c-77 -20 -299 -71 -495 -113 -71 -15 -152 -40 -187
-57 -77 -37 -154 -117 -191 -195 -24 -51 -27 -70 -27 -158 0 -94 2 -105 34
-170 85 -171 256 -257 485 -242 154 10 262 50 417 153 42 28 74 43 76 37 3 -7
26 -41 54 -77 55 -73 118 -116 170 -116 85 1 169 73 169 145 0 21 -14 90 -31
154 l-31 115 -2 335 c-3 358 -8 402 -58 508 -29 62 -100 132 -162 162 -98 45
-190 62 -366 66 -91 2 -192 0 -225 -5z m450 -837 c-9 -118 -30 -200 -63 -248
-102 -147 -356 -202 -477 -103 -102 85 -97 229 11 297 32 21 87 40 183 62 75
17 174 41 221 54 140 37 133 41 125 -62z"
        />
        <path
          d="M42895 69904 c-300 -44 -495 -190 -495 -372 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 373 11 55 -26 91 -89
104 -179 l10 -62 -36 -10 c-20 -5 -63 -16 -96 -25 -33 -9 -173 -40 -312 -70
-158 -34 -272 -63 -308 -80 -82 -39 -166 -121 -201 -197 -26 -56 -29 -74 -29
-163 0 -83 4 -109 23 -150 54 -118 138 -195 259 -237 57 -19 88 -23 193 -23
182 1 286 35 460 150 39 26 72 46 74 44 1 -2 24 -35 50 -72 56 -80 122 -127
175 -127 87 1 171 73 171 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 356 -8
402 -56 505 -49 104 -154 177 -300 211 -73 17 -355 29 -425 19z m417 -871 c-6
-59 -18 -129 -27 -155 -36 -103 -149 -187 -284 -210 -132 -22 -230 17 -278
112 -50 98 -15 191 95 249 12 7 116 34 230 61 114 26 218 53 232 58 14 6 29 6
34 2 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M44537 69895 c-350 -66 -558 -347 -557 -752 1 -181 36 -308 119 -435
116 -178 298 -272 551 -284 255 -13 460 54 600 196 77 78 114 156 115 241 0
57 -4 70 -29 103 -47 63 -123 82 -192 50 -24 -11 -53 -45 -99 -115 -75 -111
-106 -141 -185 -178 -75 -36 -209 -37 -286 -2 -100 44 -178 150 -210 287 -19
82 -14 272 9 345 66 206 227 309 419 270 74 -16 139 -60 197 -135 25 -33 67
-82 92 -108 45 -47 48 -48 105 -48 146 0 207 135 128 280 -62 114 -231 234
-389 276 -85 22 -290 27 -388 9z"
        />
        <path
          d="M46156 69900 c-283 -45 -466 -191 -466 -373 0 -98 51 -147 152 -147
65 0 86 13 137 88 53 77 106 127 166 154 43 19 67 23 175 23 111 0 130 -3 172
-23 61 -30 100 -103 102 -189 l1 -56 -65 -18 c-36 -10 -182 -44 -325 -75 -143
-31 -283 -64 -312 -74 -103 -36 -195 -119 -242 -219 -22 -49 -26 -69 -26 -156
0 -88 3 -107 27 -158 53 -112 133 -184 256 -229 78 -29 297 -32 387 -4 79 24
176 72 266 132 42 28 74 43 76 37 12 -34 109 -146 146 -168 75 -43 161 -25
221 45 35 42 34 84 -5 229 l-31 115 -2 335 c-3 356 -8 402 -56 505 -49 103
-139 168 -287 207 -90 24 -366 35 -467 19z m446 -867 c-6 -59 -18 -129 -27
-155 -36 -103 -151 -187 -289 -211 -126 -21 -226 19 -273 113 -42 82 -21 171
54 223 52 35 60 38 293 92 113 26 212 51 220 55 28 16 32 -8 22 -117z"
        />
        <path
          d="M49568 69900 c-230 -39 -400 -160 -491 -350 -67 -141 -82 -215 -81
-395 1 -118 6 -166 22 -226 93 -345 373 -527 777 -506 136 7 205 25 321 82
140 68 236 175 250 277 12 88 -49 146 -143 135 -36 -4 -56 -16 -121 -74 -142
-128 -224 -171 -337 -180 -206 -16 -369 129 -392 347 l-6 59 429 3 c379 3 434
6 475 21 84 32 119 91 119 202 0 131 -64 298 -155 402 -52 59 -156 132 -231
162 -116 45 -304 63 -436 41z m269 -280 c101 -43 174 -153 189 -285 l7 -55
-332 0 c-377 0 -343 -10 -316 95 9 33 26 79 38 104 30 59 117 137 175 156 65
21 173 14 239 -15z"
        />
        <path
          d="M50774 69896 c-39 -17 -71 -58 -84 -107 -7 -25 -10 -247 -8 -636 l3
-598 25 -45 c43 -77 149 -112 233 -76 49 20 93 77 106 136 7 31 11 181 11 383
1 355 8 438 50 535 28 65 75 107 142 127 120 35 214 7 258 -80 l25 -49 5 -461
c5 -446 6 -461 27 -500 39 -74 89 -105 166 -105 47 0 113 27 139 56 45 51 48
78 48 401 1 471 16 576 93 660 119 129 288 124 360 -11 22 -40 22 -51 27 -496
5 -493 6 -497 63 -559 63 -68 206 -65 269 5 47 53 48 65 48 572 0 280 -4 501
-10 532 -42 219 -233 346 -495 327 -139 -10 -227 -50 -334 -152 l-63 -60 -16
25 c-55 87 -165 160 -272 180 -184 35 -360 -16 -496 -142 l-61 -58 -6 50 c-12
103 -63 152 -162 157 -36 2 -72 -2 -91 -11z"
        />
        <path
          d="M53252 69893 c-43 -21 -79 -65 -92 -113 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 44 c-45
80 -156 115 -238 74z"
        />
        <path
          d="M54356 69900 c-279 -44 -466 -192 -466 -368 0 -99 53 -152 151 -152
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 93 -89 106 -179 l10 -61 -91 -26 c-50 -14 -165 -41 -256 -61 -325
-70 -356 -78 -408 -102 -79 -37 -163 -120 -198 -195 -26 -56 -29 -74 -29 -163
0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 97 -64 153 -79 301 -80 188 -1
288 31 463 148 40 27 74 47 75 45 2 -2 25 -35 51 -72 56 -80 122 -127 175
-127 87 1 171 73 171 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 358 -8 402
-58 508 -28 60 -99 132 -158 161 -94 45 -194 63 -370 67 -91 2 -192 0 -224 -5z
m454 -767 c-1 -66 -21 -211 -35 -252 -62 -188 -365 -288 -510 -168 -102 84
-97 228 11 296 32 21 88 40 183 62 75 17 177 42 226 54 112 29 125 30 125 8z"
        />
        <path
          d="M62855 69854 c-301 -45 -495 -190 -495 -372 0 -101 53 -152 157 -152
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 111 0 130 -3 172
-23 38 -19 52 -34 73 -77 15 -29 30 -78 34 -108 l7 -55 -70 -20 c-88 -26 -257
-65 -441 -102 -158 -32 -222 -52 -281 -86 -59 -35 -131 -115 -162 -182 -24
-50 -27 -70 -27 -157 0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 97 -64
153 -79 301 -80 186 -1 289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72
57 -82 121 -127 178 -127 84 0 168 73 168 145 0 21 -14 90 -31 154 l-31 115
-2 335 c-3 272 -6 348 -19 398 -35 134 -90 210 -186 261 -101 54 -194 73 -381
77 -91 2 -178 1 -195 -1z m417 -871 c-14 -133 -32 -189 -82 -246 -86 -97 -263
-151 -381 -116 -80 24 -148 110 -149 186 0 76 51 144 133 177 17 8 124 35 237
61 113 26 212 51 220 55 28 16 32 -8 22 -117z"
        />
        <path
          d="M64502 69846 c-249 -48 -426 -198 -512 -434 -75 -207 -64 -507 27
-685 112 -222 311 -340 598 -354 317 -15 576 105 681 316 40 81 41 172 1 224
-34 45 -74 67 -122 67 -68 0 -100 -25 -171 -131 -105 -158 -180 -204 -329
-204 -108 1 -160 21 -233 89 -86 81 -132 209 -132 371 0 270 128 454 327 472
129 12 229 -34 312 -141 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 146 0
207 135 128 280 -62 114 -232 234 -389 276 -84 22 -291 27 -383 10z"
        />
        <path
          d="M66135 69854 c-301 -45 -495 -190 -495 -372 0 -101 53 -152 157 -152
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 111 0 130 -3 172
-23 38 -19 52 -34 73 -77 15 -29 30 -78 34 -108 l7 -55 -70 -20 c-88 -26 -257
-65 -441 -102 -158 -32 -222 -52 -281 -86 -59 -35 -131 -115 -162 -182 -24
-50 -27 -70 -27 -157 0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 97 -64
153 -79 301 -80 186 -1 289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72
57 -82 121 -127 178 -127 84 0 168 73 168 145 0 21 -14 90 -31 154 l-31 115
-2 335 c-3 272 -6 348 -19 398 -35 134 -90 210 -186 261 -101 54 -194 73 -381
77 -91 2 -178 1 -195 -1z m417 -871 c-14 -133 -32 -189 -82 -246 -86 -97 -263
-151 -381 -116 -80 24 -148 110 -149 186 0 76 51 144 133 177 17 8 124 35 237
61 113 26 212 51 220 55 28 16 32 -8 22 -117z"
        />
        <path
          d="M69505 69845 c-214 -38 -379 -161 -471 -350 -64 -133 -78 -201 -77
-385 0 -125 4 -170 21 -231 94 -346 373 -527 777 -506 132 7 230 33 337 89
214 111 311 336 170 395 -67 28 -112 14 -194 -59 -160 -141 -225 -176 -341
-185 -208 -16 -374 131 -394 349 l-6 57 429 4 c306 2 440 7 467 16 53 17 102
63 115 109 41 135 -25 364 -143 499 -52 59 -156 132 -231 162 -125 49 -310 64
-459 36z m293 -275 c99 -43 173 -154 188 -285 l7 -55 -332 0 c-376 0 -343 -10
-316 93 33 128 110 224 207 259 70 25 173 20 246 -12z"
        />
        <path
          d="M70720 69838 c-19 -13 -45 -43 -58 -68 l-22 -45 2 -610 3 -610 25
-45 c43 -77 149 -112 233 -76 49 20 93 77 106 136 7 31 11 181 11 383 1 354 9
441 49 533 42 94 102 133 215 141 96 6 149 -20 186 -92 l25 -49 5 -456 c6
-492 6 -497 63 -559 48 -52 139 -66 212 -32 98 45 104 69 105 441 1 469 16
573 93 657 119 129 288 124 360 -11 22 -40 22 -51 27 -496 5 -438 6 -457 26
-501 71 -153 297 -141 344 19 8 25 10 197 8 542 -3 496 -3 506 -25 560 -33 81
-79 138 -147 182 -88 57 -150 73 -286 73 -170 0 -259 -35 -379 -149 l-63 -61
-16 25 c-54 85 -168 160 -271 180 -185 35 -361 -16 -497 -142 l-61 -58 -6 50
c-13 105 -63 152 -167 158 -55 3 -70 0 -100 -20z"
        />
        <path
          d="M73212 69843 c-43 -21 -79 -65 -92 -113 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 44 c-45
80 -156 115 -238 74z"
        />
        <path
          d="M74315 69850 c-274 -42 -465 -193 -465 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 373 11 55 -26 91 -89
104 -178 8 -54 7 -63 -6 -67 -65 -20 -241 -62 -426 -101 -241 -51 -290 -66
-350 -101 -62 -36 -131 -113 -162 -179 -25 -53 -28 -72 -28 -160 0 -88 3 -107
27 -158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 187 -1 288 32 466
150 42 28 74 43 76 37 3 -7 26 -41 54 -77 55 -73 118 -116 170 -116 84 1 169
73 169 144 0 20 -14 90 -31 154 l-30 117 -3 335 c-2 271 -6 347 -19 397 -48
183 -143 273 -337 318 -87 20 -361 29 -455 15z m446 -866 c-13 -133 -31 -190
-81 -246 -87 -99 -261 -152 -381 -117 -81 25 -149 110 -149 189 0 19 9 54 20
77 28 63 88 96 229 129 175 40 306 73 332 83 13 5 28 5 32 1 5 -4 4 -56 -2
-116z"
        />
        <path
          d="M9130 69154 c-14 -2 -59 -9 -100 -15 -101 -14 -240 -54 -326 -93
-277 -128 -502 -403 -568 -693 -77 -345 29 -674 304 -936 182 -175 404 -280
668 -318 129 -18 461 -6 587 21 229 49 416 151 565 306 136 142 219 288 262
460 30 121 32 351 4 468 -39 168 -123 320 -250 454 -165 174 -358 275 -626
328 -81 16 -457 29 -520 18z"
        />
        <path
          d="M12820 69145 c-496 -62 -855 -282 -1019 -626 -56 -117 -81 -221 -88
-364 -23 -508 314 -887 905 -1015 239 -52 54 -50 4142 -50 4096 0 3901 -2
4147 51 418 90 719 302 858 603 58 126 69 183 70 366 0 141 -3 177 -23 248
-72 266 -266 488 -547 627 -172 85 -383 139 -644 165 -167 16 -7665 12 -7801
-5z"
        />
        <path
          d="M32712 68383 c-40 -19 -66 -55 -81 -108 -7 -25 -11 -151 -11 -338 l0
-298 -72 61 c-40 34 -100 74 -133 91 -161 79 -390 78 -555 -3 -93 -45 -205
-159 -252 -258 -64 -133 -72 -180 -73 -405 0 -179 2 -208 23 -279 92 -319 318
-499 607 -483 156 9 268 59 395 176 30 28 55 51 57 51 2 0 3 -21 3 -47 0 -101
75 -183 169 -183 96 0 156 56 172 162 6 39 9 387 7 903 l-3 840 -23 47 c-17
35 -34 52 -66 67 -51 25 -119 27 -164 4z m-337 -836 c57 -28 133 -105 163
-163 42 -83 56 -154 56 -279 0 -224 -74 -371 -219 -437 -76 -35 -191 -33 -267
5 -136 67 -211 221 -210 437 0 294 143 478 362 466 45 -2 81 -11 115 -29z"
        />
        <path
          d="M37524 68361 c-88 -40 -126 -141 -89 -237 19 -51 67 -89 128 -99 136
-25 241 71 223 203 -8 62 -32 97 -87 126 -56 31 -117 33 -175 7z"
        />
        <path
          d="M52902 68343 c-40 -19 -66 -55 -81 -108 -7 -25 -11 -151 -11 -338 l0
-298 -82 67 c-45 37 -106 78 -134 91 -112 51 -274 66 -409 38 -200 -42 -357
-192 -429 -408 -45 -133 -48 -445 -7 -586 61 -207 177 -353 342 -431 86 -40
165 -53 274 -47 151 10 267 63 383 176 29 28 55 51 58 51 2 0 4 -22 4 -48 0
-69 34 -130 90 -159 112 -60 226 -1 250 130 7 35 10 354 8 904 l-3 848 -23 47
c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-335 -836 c55 -29 132 -106
161 -163 42 -83 56 -154 56 -279 0 -173 -33 -272 -120 -365 -63 -68 -128 -94
-229 -95 -68 0 -89 4 -137 28 -136 67 -211 221 -210 437 0 240 91 402 255 456
61 20 164 11 224 -19z"
        />
        <path
          d="M67470 68343 c-34 -13 -69 -45 -86 -80 -17 -34 -20 -71 -24 -355 l-5
-316 -70 60 c-154 131 -342 177 -550 133 -199 -42 -357 -192 -429 -408 -45
-133 -48 -445 -7 -586 44 -149 133 -291 228 -363 234 -177 542 -154 768 56 32
31 60 56 61 56 1 0 4 -27 7 -60 7 -77 35 -122 93 -150 79 -39 165 -18 216 51
36 48 39 132 36 994 l-3 840 -23 47 c-17 35 -34 52 -66 67 -40 20 -112 27
-146 14z m-383 -835 c105 -39 189 -139 229 -273 25 -82 25 -276 0 -360 -38
-128 -105 -213 -201 -257 -76 -35 -191 -33 -267 5 -136 67 -211 221 -210 437
0 240 92 405 255 456 62 19 129 17 194 -8z"
        />
        <path
          d="M57714 68321 c-122 -55 -141 -232 -33 -307 47 -32 127 -43 182 -25
50 17 103 79 112 130 26 157 -116 268 -261 202z"
        />
        <path
          d="M72264 68311 c-131 -60 -140 -252 -14 -318 111 -59 256 2 275 116 26
157 -116 268 -261 202z"
        />
        <path
          d="M27148 67840 c-285 -45 -468 -191 -468 -373 0 -96 53 -147 151 -147
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 95 -92 107 -181 l9 -61 -36 -10 c-101 -28 -257 -64 -447 -104 -135
-28 -232 -54 -270 -72 -82 -39 -165 -120 -200 -197 -25 -55 -29 -73 -29 -162
0 -92 3 -105 32 -168 81 -171 257 -259 487 -244 155 10 256 47 410 148 45 30
81 48 83 42 12 -34 109 -146 146 -168 75 -43 161 -25 221 45 35 42 34 84 -5
229 l-31 115 -2 335 c-3 356 -8 402 -56 505 -49 104 -154 177 -300 211 -87 20
-363 29 -452 15z m444 -867 c-6 -59 -18 -129 -27 -155 -42 -122 -199 -218
-357 -218 -161 0 -274 151 -208 279 30 61 94 96 228 127 177 40 307 73 333 83
13 5 28 5 33 1 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M28870 67844 c-189 -34 -304 -88 -408 -194 -144 -146 -211 -355 -199
-614 7 -146 24 -214 79 -325 107 -215 309 -333 588 -347 255 -13 460 54 600
196 77 78 114 156 115 241 0 57 -4 70 -29 103 -47 63 -123 82 -192 50 -24 -11
-53 -45 -100 -115 -105 -158 -180 -204 -329 -204 -108 1 -160 21 -233 89 -95
89 -137 222 -129 406 3 63 12 136 20 161 66 206 227 309 419 270 74 -16 139
-60 197 -135 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 46 0 67 5 95 24 42
29 69 79 69 130 0 117 -122 267 -280 344 -47 23 -112 49 -145 58 -58 15 -283
27 -335 18z"
        />
        <path
          d="M30428 67840 c-285 -45 -468 -191 -468 -373 0 -96 53 -147 151 -147
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 95 -92 107 -181 l9 -61 -36 -10 c-101 -28 -257 -64 -447 -104 -135
-28 -232 -54 -270 -72 -82 -39 -165 -120 -200 -197 -25 -55 -29 -73 -29 -162
0 -92 3 -105 32 -168 81 -171 257 -259 487 -244 155 10 256 47 410 148 45 30
81 48 83 42 12 -34 109 -146 146 -168 75 -43 161 -25 221 45 35 42 34 84 -5
229 l-31 115 -2 335 c-3 356 -8 402 -56 505 -49 104 -154 177 -300 211 -87 20
-363 29 -452 15z m444 -867 c-6 -59 -18 -129 -27 -155 -42 -122 -199 -218
-357 -218 -161 0 -274 151 -208 279 30 61 94 96 228 127 177 40 307 73 333 83
13 5 28 5 33 1 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M33825 67835 c-265 -48 -454 -226 -527 -495 -31 -118 -32 -356 0
-473 90 -329 345 -507 727 -506 316 0 595 165 622 368 11 80 -54 138 -144 128
-36 -4 -57 -17 -140 -91 -125 -111 -210 -155 -314 -163 -211 -16 -373 127
-396 348 l-6 59 395 0 c406 0 470 5 544 41 63 30 79 70 79 194 -1 111 -15 167
-71 275 -63 122 -181 228 -310 279 -125 49 -310 64 -459 36z m292 -275 c101
-43 174 -153 189 -285 l7 -55 -333 0 -333 0 7 43 c3 23 16 71 28 107 30 86
119 180 194 204 67 22 174 16 241 -14z"
        />
        <path
          d="M35041 67828 c-19 -13 -44 -39 -55 -58 -20 -34 -21 -50 -24 -643 l-3
-609 24 -52 c29 -63 77 -97 147 -104 65 -6 112 12 153 57 52 58 57 98 57 474
1 355 8 438 50 535 28 65 75 107 142 127 121 35 215 6 258 -80 l25 -50 5 -460
c5 -446 6 -461 27 -500 52 -98 149 -132 248 -86 98 45 104 69 105 441 1 329
10 474 35 559 22 73 89 144 161 171 117 43 204 15 257 -85 21 -39 22 -53 27
-495 5 -493 6 -497 63 -559 63 -68 206 -65 269 5 47 53 48 65 48 571 0 509 -5
564 -50 653 -66 129 -224 209 -412 210 -147 0 -261 -46 -370 -148 l-71 -67
-15 25 c-27 42 -109 118 -151 140 -58 29 -155 50 -236 50 -146 0 -272 -50
-378 -150 l-64 -60 -6 50 c-13 105 -63 152 -167 158 -55 3 -70 0 -99 -20z"
        />
        <path
          d="M37525 67836 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-43
77 -152 112 -235 76z"
        />
        <path
          d="M38628 67840 c-281 -45 -468 -191 -468 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 374 10 37 -17 50 -32
72 -77 15 -31 30 -79 32 -108 l4 -53 -68 -20 c-86 -25 -245 -62 -436 -101 -82
-17 -172 -39 -202 -50 -106 -37 -198 -119 -245 -220 -22 -49 -26 -69 -26 -156
0 -88 3 -107 27 -158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 186 -1
289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72 82 -117 167 -152 255
-107 58 29 91 74 91 121 0 22 -14 93 -31 157 l-30 117 -3 335 c-3 357 -8 401
-58 507 -29 62 -100 132 -162 162 -98 45 -190 62 -366 66 -91 2 -191 0 -222
-5z m447 -837 c-13 -174 -46 -254 -132 -319 -87 -66 -235 -100 -327 -74 -123
34 -189 166 -136 271 31 60 92 93 229 125 64 15 166 39 226 54 160 41 148 46
140 -57z"
        />
        <path
          d="M47355 67804 c-301 -45 -495 -190 -495 -372 0 -101 53 -152 157 -152
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 110 0 131 -3 171
-23 59 -29 99 -97 106 -182 l6 -58 -69 -21 c-86 -25 -154 -41 -416 -96 -128
-27 -230 -55 -265 -71 -81 -39 -164 -120 -199 -197 -25 -55 -29 -73 -29 -162
0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 100 -66 152 -80 311 -80 127 0
147 3 220 28 87 30 158 67 247 130 32 22 59 38 61 35 2 -2 25 -35 51 -72 57
-82 121 -127 178 -127 48 0 107 29 142 70 35 42 34 84 -5 229 l-31 115 -2 335
c-3 358 -8 402 -58 508 -28 60 -99 132 -158 161 -94 45 -194 63 -370 67 -91 2
-178 1 -195 -1z m425 -776 c-1 -62 -21 -205 -35 -248 -62 -187 -365 -287 -510
-167 -70 58 -93 152 -55 226 30 61 94 96 228 127 65 14 164 39 222 54 136 36
150 36 150 8z"
        />
        <path
          d="M49050 67804 c-261 -47 -426 -160 -524 -359 -65 -131 -81 -209 -81
-395 0 -142 3 -176 23 -245 34 -118 86 -208 167 -290 123 -124 267 -181 480
-192 318 -15 576 105 681 316 22 44 28 70 29 122 0 57 -4 70 -29 103 -35 47
-86 70 -142 63 -58 -6 -83 -28 -154 -134 -100 -151 -178 -198 -325 -198 -111
1 -171 24 -238 93 -84 86 -127 208 -127 363 0 164 40 288 125 377 78 84 195
118 317 93 74 -16 139 -60 197 -135 25 -33 67 -82 92 -108 45 -47 48 -48 105
-48 146 0 207 135 128 280 -62 114 -232 234 -389 276 -58 15 -283 27 -335 18z"
        />
        <path
          d="M50635 67804 c-301 -45 -495 -190 -495 -372 0 -101 53 -152 157 -152
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 110 0 131 -3 171
-23 59 -29 99 -97 106 -182 l6 -58 -69 -21 c-86 -25 -154 -41 -416 -96 -128
-27 -230 -55 -265 -71 -81 -39 -164 -120 -199 -197 -25 -55 -29 -73 -29 -162
0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 100 -66 152 -80 311 -80 127 0
147 3 220 28 87 30 158 67 247 130 32 22 59 38 61 35 2 -2 25 -35 51 -72 57
-82 121 -127 178 -127 48 0 107 29 142 70 35 42 34 84 -5 229 l-31 115 -2 335
c-3 358 -8 402 -58 508 -28 60 -99 132 -158 161 -94 45 -194 63 -370 67 -91 2
-178 1 -195 -1z m425 -776 c-1 -62 -21 -205 -35 -248 -62 -187 -365 -287 -510
-167 -70 58 -93 152 -55 226 30 61 94 96 228 127 65 14 164 39 222 54 136 36
150 36 150 8z"
        />
        <path
          d="M54028 67800 c-230 -39 -400 -160 -491 -350 -67 -141 -82 -215 -81
-395 1 -172 19 -256 77 -372 89 -176 232 -287 437 -339 103 -26 355 -27 450
-1 155 42 293 130 359 228 92 137 47 260 -90 247 -43 -4 -57 -13 -145 -91
-148 -131 -250 -175 -379 -165 -121 10 -231 82 -284 187 -26 49 -51 143 -51
188 l0 32 428 3 c379 3 432 6 472 21 96 38 127 101 117 240 -17 232 -169 441
-383 526 -116 45 -304 63 -436 41z m269 -280 c101 -43 174 -153 189 -285 l7
-55 -332 0 c-376 0 -343 -10 -316 93 8 32 26 79 39 106 32 62 115 137 175 156
64 21 172 14 238 -15z"
        />
        <path
          d="M55220 67788 c-19 -13 -45 -43 -58 -68 l-22 -45 2 -610 3 -610 25
-44 c44 -78 149 -113 233 -77 49 20 93 77 106 136 7 31 11 181 11 383 1 354 9
441 49 533 42 94 102 133 215 141 97 6 150 -20 186 -92 l25 -50 5 -460 c5
-446 6 -461 27 -500 39 -74 89 -105 166 -105 47 0 113 27 139 56 45 51 48 78
48 401 1 329 10 477 35 560 22 73 90 146 161 172 117 44 204 16 257 -84 21
-39 22 -53 27 -500 5 -447 6 -461 27 -500 40 -75 89 -105 169 -105 47 0 107
25 136 57 47 52 48 64 48 571 0 280 -4 501 -10 532 -39 206 -208 329 -452 330
-145 0 -262 -47 -371 -149 l-68 -64 -16 24 c-59 88 -167 159 -273 179 -184 35
-360 -16 -497 -144 l-63 -58 0 38 c-1 54 -31 116 -71 145 -50 36 -151 39 -199
7z"
        />
        <path
          d="M57715 67796 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-43
77 -152 112 -235 76z"
        />
        <path
          d="M58845 67804 c-304 -45 -495 -190 -495 -377 0 -98 52 -147 157 -147
64 0 81 11 133 90 36 55 102 120 140 140 91 47 287 52 374 10 37 -17 50 -32
72 -77 15 -30 30 -79 32 -108 4 -52 4 -53 -29 -63 -87 -27 -201 -55 -403 -97
-120 -25 -242 -54 -271 -64 -105 -37 -197 -119 -242 -216 -25 -53 -28 -71 -28
-160 0 -108 15 -155 73 -239 38 -55 130 -120 209 -148 57 -19 88 -23 193 -23
182 1 287 35 459 150 39 26 73 46 74 44 2 -2 25 -35 51 -72 56 -80 122 -127
175 -127 86 1 171 73 171 144 0 20 -14 90 -31 154 l-30 117 -3 335 c-2 271 -6
347 -19 397 -48 183 -143 273 -337 318 -73 17 -354 29 -425 19z m417 -871
c-13 -131 -32 -187 -78 -242 -86 -100 -265 -155 -385 -120 -80 24 -148 110
-149 186 0 71 41 131 118 172 12 7 116 34 230 61 114 26 218 53 232 58 14 6
29 6 34 2 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M61855 67786 c-267 -45 -445 -192 -445 -369 0 -96 53 -147 151 -147
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 95 -92 107 -180 l8 -60 -110 -31 c-61 -16 -187 -46 -281 -66 -262
-55 -306 -66 -362 -92 -79 -36 -157 -113 -195 -191 -31 -62 -33 -74 -33 -167
0 -92 3 -106 32 -168 81 -170 258 -259 487 -244 155 10 263 49 411 149 41 28
77 49 78 47 2 -2 25 -35 51 -72 56 -80 122 -127 175 -127 49 1 111 31 144 69
36 43 35 81 -4 229 l-30 117 -3 335 c-2 271 -6 347 -19 397 -48 183 -143 273
-337 318 -100 23 -366 29 -475 11z m466 -862 c-13 -133 -31 -190 -81 -246 -85
-98 -263 -152 -381 -117 -80 24 -148 110 -149 186 0 76 51 144 133 177 17 8
124 35 237 61 113 26 212 51 220 55 27 16 31 -6 21 -116z"
        />
        <path
          d="M63562 67786 c-356 -68 -563 -346 -562 -753 1 -183 36 -311 121 -438
64 -95 133 -156 234 -205 108 -54 178 -70 320 -77 319 -14 576 105 681 316 22
44 28 70 29 122 0 57 -4 70 -29 103 -47 63 -123 82 -192 50 -24 -11 -53 -45
-99 -115 -75 -111 -106 -141 -185 -178 -75 -36 -209 -37 -286 -2 -100 44 -178
150 -210 287 -19 82 -14 272 9 345 67 207 226 310 419 270 74 -16 139 -60 197
-135 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 46 0 67 5 95 24 42 29 69
79 69 130 0 117 -122 267 -280 344 -47 23 -112 49 -145 58 -84 22 -291 27
-383 10z"
        />
        <path
          d="M65168 67790 c-283 -45 -468 -191 -468 -370 0 -101 52 -150 158 -150
65 0 78 10 140 100 44 65 100 116 157 142 43 19 67 23 175 23 156 0 202 -17
243 -93 20 -38 52 -172 41 -172 -2 0 -52 -13 -111 -29 -60 -16 -184 -46 -278
-65 -266 -56 -306 -67 -365 -94 -78 -36 -161 -119 -196 -195 -25 -55 -29 -73
-29 -162 0 -92 3 -105 32 -168 81 -170 258 -259 487 -244 154 10 263 50 416
153 45 30 75 44 77 37 12 -34 109 -146 146 -168 76 -44 166 -24 221 48 34 45
33 83 -5 226 l-31 115 -2 335 c-3 358 -8 402 -58 508 -28 60 -99 132 -158 161
-94 45 -194 63 -370 67 -91 2 -191 0 -222 -5z m444 -867 c-6 -59 -18 -129 -27
-155 -42 -122 -199 -218 -357 -218 -163 0 -274 151 -208 281 31 60 92 93 229
125 175 40 306 73 332 83 13 5 28 5 33 1 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M68578 67790 c-282 -48 -475 -223 -549 -500 -32 -118 -32 -357 -1
-471 69 -255 237 -420 492 -485 115 -29 357 -27 464 4 211 61 374 199 392 334
12 88 -49 146 -143 135 -36 -4 -56 -17 -132 -83 -107 -95 -180 -140 -258 -161
-159 -42 -332 28 -406 165 -30 54 -57 150 -57 199 l0 32 428 3 c477 4 492 6
551 73 68 78 51 275 -39 443 -87 163 -258 279 -460 311 -74 12 -212 12 -282 1z
m270 -280 c99 -43 173 -154 188 -285 l7 -55 -332 0 c-377 0 -343 -10 -316 95
9 33 26 79 38 104 30 59 117 137 175 156 65 21 173 14 240 -15z"
        />
        <path
          d="M69794 67786 c-39 -17 -71 -58 -84 -107 -7 -25 -10 -247 -8 -636 3
-597 3 -598 25 -640 14 -25 40 -52 65 -67 24 -14 37 -26 30 -26 -25 0 -69 -39
-88 -77 -17 -34 -20 -71 -24 -355 l-5 -316 -70 60 c-122 104 -244 148 -410
148 -170 0 -300 -53 -415 -169 -82 -82 -125 -157 -162 -279 -29 -98 -37 -376
-13 -493 71 -353 312 -564 625 -546 155 9 265 59 390 176 30 28 55 51 56 51 1
0 4 -27 7 -60 7 -77 35 -122 93 -150 79 -39 165 -18 216 51 36 48 39 132 36
994 l-3 840 -23 46 c-14 29 -35 53 -57 65 l-34 18 27 12 c45 18 89 77 101 134
7 31 11 181 11 383 1 355 8 438 50 535 28 65 75 107 142 127 121 35 215 6 258
-80 l25 -50 5 -460 c5 -446 6 -461 27 -500 39 -74 89 -105 166 -105 47 0 113
27 139 56 45 51 48 78 48 396 0 484 17 592 102 675 71 68 138 91 223 79 53 -8
97 -43 128 -100 22 -40 22 -51 27 -496 5 -493 6 -497 63 -559 63 -68 206 -65
269 5 47 53 48 64 48 576 0 514 -4 557 -51 649 -100 197 -419 270 -659 151
-30 -15 -85 -56 -123 -91 l-68 -64 -16 24 c-29 43 -111 118 -152 139 -58 29
-155 50 -236 50 -146 0 -272 -51 -382 -154 l-63 -58 0 38 c-1 54 -31 116 -71
145 -41 29 -137 37 -185 15z m-329 -2319 c57 -28 133 -105 163 -163 42 -83 56
-154 56 -279 0 -173 -33 -272 -120 -365 -63 -68 -128 -94 -229 -95 -68 0 -89
4 -137 28 -135 66 -211 221 -210 432 0 298 142 483 362 471 45 -2 81 -11 115
-29z"
        />
        <path
          d="M72262 67783 c-43 -21 -79 -65 -92 -113 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-44
79 -156 114 -238 73z"
        />
        <path
          d="M73368 67790 c-281 -45 -468 -191 -468 -368 0 -99 53 -152 151 -152
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 111 0 130 -3 172
-23 39 -19 52 -33 74 -79 15 -31 30 -79 32 -108 l4 -53 -68 -21 c-38 -11 -152
-38 -254 -61 -102 -22 -228 -49 -280 -61 -52 -12 -123 -35 -157 -51 -77 -37
-154 -117 -191 -195 -24 -51 -27 -70 -27 -158 0 -87 3 -107 26 -155 56 -117
133 -187 257 -232 78 -29 297 -32 387 -4 80 24 175 71 265 132 45 30 75 44 77
37 2 -6 26 -41 54 -77 55 -73 118 -116 170 -116 85 1 169 73 169 145 0 21 -14
90 -31 154 l-31 115 -2 335 c-3 272 -6 348 -19 398 -25 93 -56 153 -107 203
-93 93 -218 130 -460 135 -91 2 -191 0 -222 -5z m444 -867 c-6 -59 -18 -129
-27 -154 -64 -187 -366 -285 -510 -166 -71 58 -93 152 -55 228 31 60 92 93
229 125 175 40 306 73 332 83 13 5 28 5 33 1 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M56700 66313 c-34 -13 -69 -45 -86 -80 -17 -34 -20 -71 -24 -355 l-5
-316 -70 60 c-122 104 -244 148 -410 148 -170 0 -300 -53 -415 -169 -82 -82
-125 -157 -162 -279 -29 -98 -37 -376 -13 -493 71 -353 312 -564 625 -546 155
9 265 59 390 176 30 28 55 51 56 51 1 0 4 -27 7 -60 7 -77 35 -122 93 -150 79
-39 165 -18 216 51 36 48 39 132 36 994 l-3 840 -23 47 c-17 35 -34 52 -66 67
-40 20 -112 27 -146 14z m-355 -846 c57 -28 133 -105 163 -163 42 -83 56 -154
56 -279 0 -173 -33 -272 -120 -365 -63 -68 -128 -94 -229 -95 -68 0 -89 4
-137 28 -135 66 -211 221 -210 432 0 298 142 483 362 471 45 -2 81 -11 115
-29z"
        />
        <path
          d="M31072 66283 c-37 -18 -63 -52 -80 -102 -8 -23 -12 -135 -12 -338 l0
-304 -82 67 c-129 105 -239 144 -404 144 -170 -1 -300 -54 -414 -169 -82 -82
-125 -157 -162 -279 -29 -98 -37 -376 -13 -493 46 -230 167 -405 340 -490 98
-48 173 -63 285 -56 153 9 263 59 390 176 30 28 55 51 57 51 2 0 3 -22 3 -48
0 -111 81 -187 188 -179 89 6 137 56 153 157 6 41 9 376 7 905 l-3 840 -23 47
c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-337 -836 c57 -28 133 -105
163 -163 42 -82 56 -154 56 -279 0 -223 -74 -371 -219 -437 -76 -35 -191 -33
-267 5 -135 66 -211 221 -210 432 0 298 142 483 362 471 45 -2 81 -11 115 -29z"
        />
        <path
          d="M61494 66281 c-131 -60 -140 -252 -14 -318 111 -59 256 2 275 116 26
157 -116 268 -261 202z"
        />
        <path
          d="M35885 66261 c-71 -33 -105 -86 -105 -163 0 -95 68 -168 165 -176 70
-6 116 11 158 57 81 90 49 241 -61 286 -47 20 -109 18 -157 -4z"
        />
        <path
          d="M51108 65760 c-281 -45 -468 -191 -468 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 374 10 37 -17 50 -32
72 -77 15 -31 30 -79 32 -108 l4 -53 -68 -20 c-86 -25 -245 -62 -436 -101 -82
-17 -172 -39 -202 -50 -106 -37 -198 -119 -245 -220 -22 -49 -26 -69 -26 -156
0 -88 3 -107 27 -158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 186 -1
289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72 82 -117 167 -152 255
-107 58 29 91 74 91 121 0 22 -14 93 -31 157 l-30 117 -3 335 c-3 357 -8 401
-58 507 -29 62 -100 132 -162 162 -98 45 -190 62 -366 66 -91 2 -191 0 -222
-5z m447 -837 c-13 -174 -46 -254 -132 -319 -87 -66 -235 -100 -327 -74 -123
34 -189 166 -136 271 31 60 92 93 229 125 64 15 166 39 226 54 160 41 148 46
140 -57z"
        />
        <path
          d="M52777 65755 c-259 -49 -444 -219 -523 -480 -25 -82 -28 -105 -28
-260 -1 -149 2 -180 22 -250 54 -185 167 -327 322 -403 112 -55 181 -72 325
-79 203 -9 365 30 507 124 127 83 202 199 203 314 0 57 -4 70 -29 103 -35 47
-86 70 -142 63 -58 -6 -83 -28 -154 -134 -100 -151 -178 -198 -325 -198 -111
1 -171 24 -238 93 -94 96 -132 220 -124 402 11 259 131 420 326 437 126 12
228 -34 310 -141 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 146 0 207 135
128 280 -62 114 -232 234 -389 276 -86 22 -291 27 -388 9z"
        />
        <path
          d="M54388 65760 c-281 -45 -468 -191 -468 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 374 10 37 -17 50 -32
72 -77 15 -31 30 -79 32 -108 l4 -53 -68 -20 c-86 -25 -245 -62 -436 -101 -82
-17 -172 -39 -202 -50 -106 -37 -198 -119 -245 -220 -22 -49 -26 -69 -26 -156
0 -88 3 -107 27 -158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 186 -1
289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72 82 -117 167 -152 255
-107 58 29 91 74 91 121 0 22 -14 93 -31 157 l-30 117 -3 335 c-3 357 -8 401
-58 507 -29 62 -100 132 -162 162 -98 45 -190 62 -366 66 -91 2 -191 0 -222
-5z m447 -837 c-13 -174 -46 -254 -132 -319 -87 -66 -235 -100 -327 -74 -123
34 -189 166 -136 271 31 60 92 93 229 125 64 15 166 39 226 54 160 41 148 46
140 -57z"
        />
        <path
          d="M57785 65755 c-262 -47 -455 -227 -527 -492 -30 -112 -31 -363 0
-474 69 -256 237 -420 492 -485 114 -29 357 -27 464 4 158 45 279 124 345 223
71 106 64 202 -16 236 -68 28 -113 14 -195 -59 -150 -133 -230 -176 -343 -185
-206 -16 -369 129 -392 347 l-6 59 429 4 c462 3 479 5 538 57 59 52 72 163 36
304 -62 245 -248 416 -500 462 -93 16 -232 16 -325 -1z m292 -275 c101 -43
174 -153 189 -285 l7 -55 -333 0 -333 0 7 43 c3 23 16 71 28 107 30 86 119
180 194 204 67 22 174 16 241 -14z"
        />
        <path
          d="M59001 65748 c-19 -13 -44 -39 -55 -58 -20 -34 -21 -50 -24 -645 l-3
-610 28 -55 c22 -43 38 -60 73 -77 115 -59 242 1 269 127 7 31 11 181 11 383
1 354 9 441 49 533 42 94 102 133 215 141 96 6 149 -20 186 -92 l25 -49 5
-456 c6 -492 6 -497 63 -559 48 -52 139 -66 212 -32 98 45 104 69 105 441 1
469 16 573 93 657 119 129 288 124 360 -11 22 -40 22 -51 27 -496 5 -438 6
-457 26 -501 71 -153 297 -141 344 19 8 25 10 194 8 537 -4 554 -4 553 -71
652 -39 57 -137 126 -210 148 -75 23 -213 30 -289 16 -93 -18 -183 -67 -262
-141 l-69 -65 -16 25 c-82 124 -217 190 -388 190 -144 0 -270 -51 -376 -150
l-64 -60 -6 50 c-13 105 -63 152 -167 158 -55 3 -70 0 -99 -20z"
        />
        <path
          d="M61495 65756 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 44 c-44
78 -152 113 -235 77z"
        />
        <path
          d="M62595 65760 c-276 -42 -465 -193 -465 -370 0 -99 52 -150 152 -150
64 0 86 14 129 78 45 66 108 130 149 152 91 47 287 52 373 11 60 -29 98 -98
100 -183 l2 -61 -70 -19 c-77 -20 -299 -71 -495 -113 -71 -15 -152 -40 -187
-57 -77 -37 -154 -117 -191 -195 -24 -51 -27 -70 -27 -158 0 -94 2 -105 34
-170 85 -171 256 -257 485 -242 154 10 262 50 417 153 42 28 74 43 76 37 3 -7
26 -41 54 -77 55 -73 118 -116 170 -116 85 1 169 73 169 145 0 21 -14 90 -31
154 l-31 115 -2 335 c-3 358 -8 402 -58 508 -29 62 -100 132 -162 162 -98 45
-190 62 -366 66 -91 2 -192 0 -225 -5z m450 -837 c-9 -118 -30 -200 -63 -248
-102 -147 -356 -202 -477 -103 -102 85 -97 229 11 297 32 21 87 40 183 62 75
17 174 41 221 54 140 37 133 41 125 -62z"
        />
        <path
          d="M64215 65756 c-264 -45 -445 -193 -445 -364 0 -99 53 -152 151 -152
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 95 -92 107 -181 l9 -61 -38 -10 c-110 -30 -264 -66 -432 -101 -103
-21 -212 -48 -242 -58 -105 -37 -197 -119 -242 -216 -25 -53 -28 -72 -28 -160
0 -94 2 -105 34 -170 85 -172 255 -257 485 -242 153 10 262 50 416 152 39 26
72 46 74 44 1 -2 24 -35 50 -72 56 -80 122 -127 175 -127 86 1 171 73 171 144
0 20 -14 90 -31 154 l-30 117 -3 335 c-3 355 -8 402 -56 504 -49 103 -139 168
-287 207 -102 27 -370 35 -488 15z m470 -833 c-13 -173 -46 -254 -132 -319
-86 -66 -236 -100 -327 -74 -123 34 -189 166 -136 271 32 63 91 93 249 130 75
17 174 41 221 54 139 37 132 40 125 -62z"
        />
        <path
          d="M65960 65764 c-261 -47 -426 -160 -524 -359 -65 -131 -81 -209 -81
-395 0 -141 3 -176 23 -243 56 -195 169 -334 337 -413 119 -57 181 -68 360
-68 178 0 256 15 380 76 222 108 346 338 251 463 -33 43 -74 65 -121 65 -68 0
-100 -25 -170 -131 -75 -111 -106 -141 -185 -178 -47 -22 -68 -26 -145 -26
-111 1 -171 24 -238 93 -94 96 -132 220 -124 402 11 259 131 420 326 437 126
12 228 -34 310 -141 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 46 0 67 5
95 24 42 29 69 79 69 130 0 117 -122 267 -280 344 -47 23 -112 49 -145 58 -58
15 -283 27 -335 18z"
        />
        <path
          d="M67495 65756 c-264 -45 -445 -193 -445 -364 0 -99 53 -152 151 -152
66 0 87 13 138 88 53 77 106 127 166 154 43 19 67 23 175 23 110 0 131 -3 171
-23 57 -28 95 -92 107 -181 l9 -61 -38 -10 c-110 -30 -264 -66 -432 -101 -103
-21 -212 -48 -242 -58 -105 -37 -197 -119 -242 -216 -25 -53 -28 -72 -28 -160
0 -94 2 -105 34 -170 85 -172 255 -257 485 -242 153 10 262 50 416 152 39 26
72 46 74 44 1 -2 24 -35 50 -72 56 -80 122 -127 175 -127 86 1 171 73 171 144
0 20 -14 90 -31 154 l-30 117 -3 335 c-3 355 -8 402 -56 504 -49 103 -139 168
-287 207 -102 27 -370 35 -488 15z m470 -833 c-13 -173 -46 -254 -132 -319
-86 -66 -236 -100 -327 -74 -123 34 -189 166 -136 271 32 63 91 93 249 130 75
17 174 41 221 54 139 37 132 40 125 -62z"
        />
        <path
          d="M70938 65760 c-230 -39 -400 -161 -491 -352 -68 -141 -81 -205 -81
-388 1 -183 19 -263 86 -393 125 -240 376 -362 713 -344 191 10 363 77 469
184 152 151 132 327 -36 311 -42 -5 -57 -13 -125 -74 -151 -134 -222 -172
-338 -181 -206 -16 -372 132 -392 349 l-6 57 429 3 c379 3 434 6 475 21 84 32
119 91 119 202 0 131 -64 298 -155 402 -52 59 -156 132 -231 162 -116 45 -304
63 -436 41z m269 -280 c101 -43 174 -153 189 -285 l7 -55 -332 0 c-377 0 -343
-10 -316 95 9 33 26 79 38 104 30 59 117 137 175 156 65 21 173 14 239 -15z"
        />
        <path
          d="M25525 65744 c-308 -47 -495 -189 -495 -377 0 -96 53 -147 151 -147
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 374 10 58 -28 98 -102
100 -187 l1 -56 -90 -24 c-49 -13 -204 -49 -344 -79 -293 -62 -351 -86 -431
-170 -28 -30 -63 -80 -78 -111 -24 -51 -27 -70 -27 -158 0 -92 3 -106 32 -168
82 -172 257 -258 494 -244 150 9 248 46 412 155 37 25 69 43 70 41 2 -2 25
-35 51 -72 56 -80 122 -127 175 -127 49 1 111 31 144 69 36 43 35 84 -4 230
l-31 115 -2 335 c-3 272 -6 348 -19 398 -35 134 -90 210 -186 261 -101 54
-194 73 -381 77 -91 2 -178 1 -195 -1z m416 -870 c-13 -133 -31 -190 -81 -246
-85 -98 -263 -152 -381 -117 -119 36 -183 174 -126 274 38 66 89 89 322 144
116 27 221 54 235 59 14 6 29 6 33 2 5 -4 4 -56 -2 -116z"
        />
        <path
          d="M27167 65735 c-298 -57 -497 -273 -548 -596 -17 -111 -6 -305 23
-404 52 -178 167 -319 319 -394 112 -55 181 -71 324 -78 319 -14 576 105 681
316 40 81 41 172 1 224 -39 51 -80 70 -137 65 -63 -6 -88 -27 -160 -135 -69
-104 -102 -135 -180 -172 -75 -36 -209 -37 -286 -2 -138 61 -223 226 -224 432
0 164 40 288 125 377 78 84 195 118 317 93 74 -16 139 -60 197 -135 25 -33 67
-82 92 -108 45 -47 48 -48 105 -48 46 0 67 5 95 24 42 29 69 79 69 130 0 117
-122 267 -280 344 -47 23 -112 49 -145 58 -86 22 -291 27 -388 9z"
        />
        <path
          d="M28815 65744 c-300 -44 -495 -190 -495 -372 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 373 11 55 -26 91 -89
104 -179 l10 -62 -36 -10 c-20 -5 -63 -16 -96 -25 -33 -9 -173 -40 -312 -70
-158 -34 -272 -63 -308 -80 -82 -39 -166 -121 -201 -197 -26 -56 -29 -74 -29
-163 0 -83 4 -109 23 -150 54 -118 138 -195 259 -237 57 -19 88 -23 193 -23
182 1 286 35 460 150 39 26 72 46 74 44 1 -2 24 -35 50 -72 56 -80 122 -127
175 -127 87 1 171 73 171 145 0 21 -14 90 -31 154 l-31 115 -2 335 c-3 356 -8
402 -56 505 -49 104 -154 177 -300 211 -73 17 -355 29 -425 19z m417 -871 c-6
-59 -18 -129 -27 -155 -36 -103 -149 -187 -284 -210 -132 -22 -230 17 -278
112 -50 98 -15 191 95 249 12 7 116 34 230 61 114 26 218 53 232 58 14 6 29 6
34 2 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M32198 65740 c-230 -39 -400 -161 -491 -352 -67 -139 -82 -214 -81
-393 1 -176 20 -260 84 -383 127 -245 376 -367 715 -349 132 7 230 33 337 89
215 111 311 336 170 395 -71 30 -110 16 -218 -80 -62 -55 -125 -100 -173 -124
-72 -36 -81 -38 -171 -38 -84 0 -102 3 -157 29 -68 31 -129 89 -161 153 -28
54 -52 140 -52 184 l0 38 428 3 c467 4 482 6 544 65 98 93 40 392 -107 560
-89 101 -235 178 -385 202 -74 12 -212 12 -282 1z m269 -280 c101 -43 174
-153 189 -285 l7 -55 -332 0 c-377 0 -343 -10 -316 95 9 33 26 79 38 104 30
59 117 137 175 156 65 21 173 14 239 -15z"
        />
        <path
          d="M33414 65736 c-39 -17 -71 -58 -84 -107 -7 -25 -10 -247 -8 -636 l3
-598 25 -45 c43 -77 149 -112 233 -76 49 20 93 77 106 136 7 31 11 181 11 383
1 355 8 438 50 535 28 65 75 107 142 127 120 35 214 7 258 -80 l25 -49 5 -461
c5 -446 6 -461 27 -500 39 -74 89 -105 166 -105 47 0 113 27 139 56 45 51 48
78 48 401 1 471 16 576 93 660 119 129 288 124 360 -11 22 -40 22 -51 27 -496
5 -493 6 -497 63 -559 63 -68 206 -65 269 5 47 53 48 65 48 572 0 280 -4 501
-10 532 -42 219 -233 346 -495 327 -139 -10 -227 -50 -334 -152 l-63 -60 -16
25 c-55 87 -165 160 -272 180 -184 35 -360 -16 -496 -142 l-61 -58 -6 50 c-12
103 -63 152 -162 157 -36 2 -72 -2 -91 -11z"
        />
        <path
          d="M35882 65733 c-43 -21 -79 -65 -92 -113 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-44
79 -156 114 -238 73z"
        />
        <path
          d="M36959 65735 c-257 -42 -438 -193 -439 -365 0 -101 52 -150 158 -150
63 0 80 11 132 90 36 55 102 120 140 140 91 47 287 52 373 11 56 -27 94 -94
103 -179 l7 -60 -34 -10 c-65 -21 -236 -61 -447 -106 -125 -27 -234 -56 -267
-71 -81 -39 -158 -114 -197 -193 -31 -62 -33 -74 -33 -167 0 -88 3 -107 27
-158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 188 -1 283 29 459 145
45 30 81 48 83 42 3 -7 26 -41 54 -77 55 -73 118 -116 170 -116 47 1 110 31
142 69 36 43 35 81 -4 229 l-30 117 -3 335 c-3 357 -8 401 -58 507 -29 62
-100 132 -162 162 -98 45 -190 62 -366 66 -112 3 -192 0 -251 -10z m476 -832
c-16 -219 -76 -314 -232 -373 -119 -45 -236 -37 -308 23 -102 83 -97 224 10
295 33 22 80 39 163 58 65 14 164 39 222 54 163 43 153 46 145 -57z"
        />
        <path
          d="M14880 65053 c-716 -31 -1255 -117 -1605 -258 -779 -313 -1300 -921
-1479 -1728 -51 -227 -60 -331 -61 -642 0 -317 9 -407 65 -640 158 -659 606
-1255 1333 -1769 l97 -69 -132 -70 c-163 -85 -380 -226 -513 -334 -112 -91
-287 -265 -366 -363 -245 -306 -404 -683 -464 -1100 -23 -154 -31 -514 -16
-677 80 -848 504 -1526 1351 -2157 l135 -101 -245 -6 c-314 -8 -437 -29 -637
-109 -130 -52 -252 -131 -353 -230 -130 -127 -197 -236 -247 -397 -24 -80 -27
-104 -27 -253 -1 -143 2 -175 22 -244 110 -380 434 -621 955 -711 206 -35 357
-36 4137 -33 4063 4 3770 0 4049 58 478 100 816 372 927 747 35 120 44 326 19
458 -54 290 -241 528 -533 678 -161 84 -310 129 -552 169 -123 21 -165 21
-2245 28 -2134 7 -2220 9 -2630 46 -737 67 -1337 219 -1640 416 -312 203 -488
495 -552 913 -21 136 -23 401 -4 505 45 250 160 432 356 559 252 165 541 246
1000 281 405 31 600 33 3055 40 2744 7 2592 4 2838 60 399 92 689 294 831 577
99 197 120 497 51 718 -62 199 -205 389 -386 512 -170 116 -403 199 -664 237
-58 8 -123 18 -145 22 -22 3 -1021 9 -2220 13 -1500 6 -2231 11 -2345 20 -670
47 -1051 110 -1329 218 -113 45 -279 136 -403 223 -128 89 -346 308 -427 429
-164 247 -227 446 -225 716 3 404 159 663 514 857 201 110 434 171 776 203
403 37 483 38 3084 45 2792 7 2612 3 2868 59 504 110 851 419 927 826 23 120
16 335 -14 450 -105 398 -459 676 -980 770 -75 13 -188 29 -251 35 -101 9
-5497 12 -5700 3z"
        />
        <path
          d="M31522 64233 c-37 -18 -63 -52 -80 -102 -8 -23 -12 -135 -12 -338 l0
-304 -82 67 c-45 37 -106 78 -134 91 -71 32 -173 53 -264 53 -311 0 -534 -194
-596 -518 -19 -99 -18 -319 2 -423 29 -157 105 -303 207 -401 60 -57 187 -124
266 -138 194 -36 387 23 534 164 32 31 60 56 61 56 2 0 6 -27 9 -60 3 -34 14
-74 26 -93 27 -43 88 -77 140 -77 96 0 156 56 172 162 6 39 9 385 7 903 l-3
840 -23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-345 -833 c58 -25
139 -103 171 -166 42 -83 56 -153 56 -279 0 -224 -74 -371 -219 -437 -76 -35
-191 -33 -267 5 -136 67 -211 221 -210 437 0 240 91 402 255 456 57 19 150 12
214 -16z"
        />
        <path
          d="M61112 64233 c-37 -18 -63 -52 -80 -102 -8 -23 -12 -135 -12 -338 l0
-304 -72 61 c-124 104 -248 150 -408 150 -309 0 -535 -195 -595 -513 -20 -105
-20 -321 1 -428 66 -349 310 -563 624 -546 154 8 260 56 390 177 30 27 55 50
57 50 2 0 3 -22 3 -48 0 -71 34 -131 90 -159 114 -59 226 0 250 130 7 35 10
354 8 904 l-3 848 -23 47 c-17 35 -34 52 -66 67 -51 25 -119 27 -164 4z m-365
-825 c105 -39 189 -140 229 -273 25 -83 25 -277 0 -360 -38 -128 -105 -213
-201 -257 -76 -35 -191 -33 -267 5 -136 67 -211 221 -210 437 0 240 92 405
255 456 62 19 129 17 194 -8z"
        />
        <path
          d="M36335 64211 c-94 -43 -130 -139 -89 -240 31 -78 145 -122 237 -92
50 17 103 79 112 130 26 157 -117 268 -260 202z"
        />
        <path
          d="M62335 64216 c-45 -20 -68 -50 -139 -179 -76 -137 -82 -153 -66 -172
27 -33 170 -9 241 40 50 36 261 236 290 277 18 25 19 32 8 39 -23 14 -299 10
-334 -5z"
        />
        <path
          d="M65924 64211 c-124 -56 -140 -235 -27 -311 30 -21 48 -25 108 -25 63
0 76 3 108 28 58 44 72 72 72 147 0 84 -22 122 -89 155 -60 30 -115 32 -172 6z"
        />
        <path
          d="M25975 63694 c-301 -45 -495 -190 -495 -372 0 -101 53 -152 157 -152
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 111 0 130 -3 172
-23 38 -19 52 -34 73 -77 15 -29 30 -78 34 -108 l7 -55 -70 -20 c-88 -26 -257
-65 -441 -102 -158 -32 -222 -52 -281 -86 -59 -35 -131 -115 -162 -182 -24
-50 -27 -70 -27 -157 0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 97 -64
153 -79 301 -80 186 -1 289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72
57 -82 121 -127 178 -127 84 0 168 73 168 145 0 21 -14 90 -31 154 l-31 115
-2 335 c-3 272 -6 348 -19 398 -35 134 -90 210 -186 261 -101 54 -194 73 -381
77 -91 2 -178 1 -195 -1z m417 -871 c-14 -133 -32 -189 -82 -246 -86 -97 -263
-151 -381 -116 -80 24 -148 110 -149 186 0 76 51 144 133 177 17 8 124 35 237
61 113 26 212 51 220 55 28 16 32 -8 22 -117z"
        />
        <path
          d="M27632 63686 c-354 -68 -562 -345 -562 -748 0 -232 62 -399 200 -538
118 -119 267 -177 475 -187 318 -14 576 106 681 316 40 81 41 172 1 224 -39
51 -80 70 -137 65 -63 -6 -88 -27 -160 -135 -69 -104 -102 -135 -180 -172 -47
-22 -69 -26 -145 -26 -108 1 -160 21 -233 89 -175 164 -179 561 -7 744 78 84
195 118 317 93 74 -16 139 -60 197 -135 25 -33 67 -82 92 -108 45 -47 48 -48
105 -48 46 0 67 5 95 24 42 29 69 79 69 130 0 117 -122 267 -280 344 -47 23
-112 49 -145 58 -84 22 -291 27 -383 10z"
        />
        <path
          d="M29215 63686 c-268 -45 -445 -192 -445 -369 0 -98 51 -147 152 -147
64 0 86 14 129 78 45 66 108 130 149 152 91 47 287 52 373 11 60 -29 98 -98
100 -183 l2 -61 -65 -18 c-36 -10 -182 -44 -325 -75 -143 -31 -283 -64 -312
-74 -103 -36 -195 -118 -240 -215 -25 -53 -28 -72 -28 -160 0 -92 3 -106 32
-168 81 -171 257 -259 487 -244 153 10 265 51 412 149 41 28 76 49 77 47 2 -2
25 -35 51 -72 56 -80 122 -127 175 -127 49 1 111 31 144 69 36 43 35 84 -4
230 l-31 115 -2 335 c-3 356 -8 402 -56 505 -49 103 -139 168 -287 207 -102
27 -370 35 -488 15z m470 -833 c-9 -118 -30 -200 -63 -248 -102 -147 -356
-202 -477 -103 -102 85 -97 229 11 297 32 21 88 40 183 62 75 17 177 42 226
54 136 35 127 40 120 -62z"
        />
        <path
          d="M32658 63690 c-280 -47 -475 -224 -550 -497 -17 -64 -21 -106 -21
-238 0 -132 4 -174 21 -238 62 -225 213 -392 421 -462 151 -52 389 -58 541
-15 209 59 368 199 387 339 11 81 -54 138 -144 128 -37 -4 -56 -16 -122 -76
-105 -95 -172 -137 -254 -163 -169 -51 -350 20 -425 169 -28 54 -52 140 -52
184 l0 38 428 3 c477 4 492 6 551 73 68 78 51 275 -39 443 -85 159 -247 273
-440 308 -81 14 -227 16 -302 4z m269 -280 c101 -43 174 -153 189 -285 l7 -55
-333 0 -333 0 7 43 c23 149 107 270 216 308 72 26 174 21 247 -11z"
        />
        <path
          d="M33850 63678 c-19 -13 -45 -43 -57 -68 l-23 -44 0 -601 0 -600 24
-51 c28 -61 77 -95 146 -102 65 -6 112 12 153 57 52 58 57 98 57 474 1 354 9
441 49 533 42 94 102 133 215 141 96 6 149 -20 186 -92 l25 -49 5 -461 c5
-446 6 -461 27 -500 39 -74 89 -105 166 -105 47 0 113 27 139 56 45 51 48 78
48 396 0 484 17 592 102 675 71 68 138 91 223 79 53 -8 97 -43 128 -100 22
-40 22 -51 27 -501 5 -447 6 -461 27 -500 40 -75 89 -105 169 -105 81 0 152
52 175 129 7 24 9 203 7 536 -4 554 -4 552 -71 652 -40 58 -137 126 -215 150
-81 25 -267 24 -342 -1 -78 -26 -146 -69 -213 -134 l-59 -57 -16 25 c-56 87
-166 160 -272 180 -183 35 -360 -15 -493 -140 l-64 -60 -6 50 c-13 105 -63
152 -167 158 -55 3 -70 0 -100 -20z"
        />
        <path
          d="M36332 63683 c-43 -21 -79 -65 -92 -113 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 44 c-45
80 -156 115 -238 74z"
        />
        <path
          d="M37435 63690 c-274 -42 -465 -193 -465 -368 0 -99 53 -152 151 -152
65 0 87 13 130 78 45 66 108 130 149 152 91 47 287 52 373 11 55 -26 91 -89
104 -178 8 -54 7 -63 -6 -67 -65 -20 -241 -62 -426 -101 -241 -51 -290 -66
-350 -101 -62 -36 -131 -113 -162 -179 -25 -53 -28 -72 -28 -160 0 -88 3 -107
27 -158 38 -80 78 -128 143 -172 96 -63 152 -78 300 -79 187 -1 288 32 466
150 42 28 74 43 76 37 3 -7 26 -41 54 -77 55 -73 118 -116 170 -116 84 1 169
73 169 144 0 20 -14 90 -31 154 l-30 117 -3 335 c-2 271 -6 347 -19 397 -48
183 -143 273 -337 318 -87 20 -361 29 -455 15z m446 -866 c-13 -133 -31 -190
-81 -246 -87 -99 -261 -152 -381 -117 -81 25 -149 110 -149 189 0 19 9 54 20
77 28 63 88 96 229 129 175 40 306 73 332 83 13 5 28 5 32 1 5 -4 4 -56 -2
-116z"
        />
        <path
          d="M55575 63694 c-301 -45 -495 -190 -495 -372 0 -101 53 -152 157 -152
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 111 0 130 -3 172
-23 38 -19 52 -34 73 -77 15 -29 30 -78 34 -108 l7 -55 -70 -20 c-88 -26 -257
-65 -441 -102 -158 -32 -222 -52 -281 -86 -59 -35 -131 -115 -162 -182 -24
-50 -27 -70 -27 -157 0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 97 -64
153 -79 301 -80 186 -1 289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72
57 -82 121 -127 178 -127 84 0 168 73 168 145 0 21 -14 90 -31 154 l-31 115
-2 335 c-3 272 -6 348 -19 398 -35 134 -90 210 -186 261 -101 54 -194 73 -381
77 -91 2 -178 1 -195 -1z m417 -871 c-14 -133 -32 -189 -82 -246 -86 -97 -263
-151 -381 -116 -80 24 -148 110 -149 186 0 76 51 144 133 177 17 8 124 35 237
61 113 26 212 51 220 55 28 16 32 -8 22 -117z"
        />
        <path
          d="M57222 63686 c-249 -48 -426 -198 -512 -434 -75 -207 -64 -507 27
-685 112 -222 311 -340 598 -354 317 -15 576 105 681 316 40 81 41 172 1 224
-34 45 -74 67 -122 67 -68 0 -100 -25 -171 -131 -105 -158 -180 -204 -329
-204 -108 1 -160 21 -233 89 -86 81 -132 209 -132 371 0 270 128 454 327 472
129 12 229 -34 312 -141 25 -33 67 -82 92 -108 45 -47 48 -48 105 -48 146 0
207 135 128 280 -62 114 -232 234 -389 276 -84 22 -291 27 -383 10z"
        />
        <path
          d="M58855 63694 c-301 -45 -495 -190 -495 -372 0 -101 53 -152 157 -152
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 111 0 130 -3 172
-23 38 -19 52 -34 73 -77 15 -29 30 -78 34 -108 l7 -55 -70 -20 c-88 -26 -257
-65 -441 -102 -158 -32 -222 -52 -281 -86 -59 -35 -131 -115 -162 -182 -24
-50 -27 -70 -27 -157 0 -87 3 -107 26 -155 40 -83 80 -132 143 -174 97 -64
153 -79 301 -80 186 -1 289 32 465 149 39 26 72 46 74 44 1 -2 24 -35 50 -72
57 -82 121 -127 178 -127 84 0 168 73 168 145 0 21 -14 90 -31 154 l-31 115
-2 335 c-3 272 -6 348 -19 398 -35 134 -90 210 -186 261 -101 54 -194 73 -381
77 -91 2 -178 1 -195 -1z m417 -871 c-14 -133 -32 -189 -82 -246 -86 -97 -263
-151 -381 -116 -80 24 -148 110 -149 186 0 76 51 144 133 177 17 8 124 35 237
61 113 26 212 51 220 55 28 16 32 -8 22 -117z"
        />
        <path
          d="M62225 63685 c-267 -48 -459 -231 -527 -501 -31 -123 -31 -352 0
-465 93 -345 373 -527 777 -506 233 12 429 105 524 248 71 106 64 202 -16 236
-70 29 -110 15 -212 -73 -112 -98 -197 -150 -269 -165 -160 -33 -323 37 -395
169 -30 55 -57 150 -57 199 l0 33 393 0 c411 0 483 6 549 43 57 31 73 74 73
192 -1 293 -213 536 -515 591 -93 16 -232 16 -325 -1z m301 -278 c99 -50 165
-154 180 -282 l7 -55 -332 0 c-376 0 -343 -10 -316 93 33 128 110 224 207 259
74 26 185 20 254 -15z"
        />
        <path
          d="M63440 63678 c-19 -13 -45 -43 -58 -68 l-22 -45 2 -610 3 -610 25
-45 c43 -77 149 -112 233 -76 49 20 93 77 106 136 7 31 11 181 11 383 1 354 9
441 49 533 42 94 102 133 215 141 96 6 149 -20 186 -92 l25 -49 5 -456 c6
-492 6 -497 63 -559 48 -52 139 -66 212 -32 98 45 104 69 105 441 1 469 16
573 93 657 119 129 288 124 360 -11 22 -40 22 -51 27 -496 5 -438 6 -457 26
-501 71 -153 297 -141 344 19 8 25 10 197 8 542 -3 496 -3 506 -25 560 -33 81
-79 138 -147 182 -88 57 -150 73 -286 73 -170 0 -259 -35 -379 -149 l-63 -61
-16 25 c-54 85 -168 160 -271 180 -185 35 -361 -16 -497 -142 l-61 -58 -6 50
c-13 105 -63 152 -167 158 -55 3 -70 0 -100 -20z"
        />
        <path
          d="M65925 63686 c-41 -18 -82 -68 -95 -116 -7 -26 -10 -238 -8 -632 3
-558 4 -595 22 -631 62 -125 242 -130 314 -9 l27 47 0 610 0 610 -25 45 c-43
77 -152 112 -235 76z"
        />
        <path
          d="M67055 63694 c-305 -45 -495 -190 -495 -377 0 -98 52 -147 157 -147
66 0 79 9 141 100 44 65 100 116 157 142 43 19 67 23 175 23 111 0 130 -3 172
-23 38 -19 52 -33 72 -75 14 -29 29 -78 34 -109 l9 -57 -111 -30 c-61 -17
-187 -46 -281 -66 -289 -60 -304 -64 -361 -91 -81 -38 -165 -121 -198 -194
-98 -219 16 -470 251 -551 59 -21 86 -24 203 -23 185 1 263 27 454 152 41 27
71 41 73 35 12 -34 109 -146 146 -168 76 -44 166 -24 221 48 34 45 33 83 -5
226 l-31 115 -2 335 c-3 358 -8 402 -58 508 -28 60 -99 132 -158 161 -94 45
-194 63 -370 67 -91 2 -178 1 -195 -1z m417 -871 c-6 -59 -18 -129 -27 -155
-42 -122 -199 -218 -357 -218 -164 0 -278 157 -205 285 38 67 88 89 327 145
118 27 224 54 235 59 11 5 24 5 29 1 4 -5 4 -56 -2 -117z"
        />
        <path
          d="M57175 58663 c-398 -7 -954 -31 -1310 -58 -4067 -307 -7927 -2068
-11790 -5379 -644 -552 -1384 -1256 -2679 -2548 -407 -406 -741 -738 -743
-738 -1 0 -4 1385 -5 3078 l-3 3077 -33 84 c-304 791 -1731 1306 -4027 1455
-555 36 -736 41 -1615 41 -876 0 -1098 -5 -1665 -41 -1932 -120 -3262 -462
-3910 -1006 -178 -149 -303 -323 -354 -492 l-21 -68 0 -24549 c0 -16811 3
-24564 10 -24597 54 -246 205 -496 441 -731 725 -719 2192 -1195 4219 -1366
672 -56 1065 -70 2005 -70 828 0 1058 6 1625 40 1966 120 3469 473 4400 1033
530 318 881 712 981 1101 19 73 19 358 19 16236 l0 16161 152 195 c914 1173
2066 2447 2978 3295 439 408 1520 1312 2105 1761 1852 1421 3448 2110 5149
2222 203 14 670 14 868 1 516 -35 943 -104 1453 -235 1598 -409 2926 -1229
3921 -2420 919 -1102 1558 -2505 1889 -4150 281 -1398 451 -3268 505 -5575 8
-315 12 -4625 13 -13965 l2 -13510 28 -85 c36 -106 103 -236 170 -326 70 -96
253 -275 372 -364 789 -593 2302 -970 4410 -1100 1052 -64 2408 -66 3435 -5
2164 131 3695 534 4510 1188 256 206 438 448 521 692 l24 70 0 15635 c0 12605
-2 15683 -13 15885 -214 4154 -1021 7993 -2323 11048 -218 511 -564 1229 -807
1672 -828 1514 -1758 2716 -2852 3689 -1201 1068 -2661 1921 -4345 2541 -2245
826 -4869 1227 -7710 1178z"
        />
        <path
          d="M15660 51534 c-585 -39 -1034 -138 -1550 -341 -729 -287 -1285 -703
-1735 -1299 -374 -495 -577 -1022 -661 -1714 -23 -183 -26 -710 -6 -900 57
-537 190 -992 413 -1418 658 -1258 2009 -2015 3919 -2198 224 -21 977 -30
1233 -15 497 30 916 91 1322 192 155 38 393 108 465 135 19 7 89 33 155 57
827 307 1538 842 1974 1487 349 516 550 1089 627 1790 21 197 30 702 15 905
-44 598 -172 1063 -430 1561 -187 360 -398 646 -667 903 -329 314 -662 522
-1044 651 -187 63 -301 83 -475 83 -280 -1 -454 -62 -607 -216 -84 -84 -149
-210 -174 -337 -22 -111 -15 -296 15 -390 43 -136 72 -171 376 -443 936 -840
1231 -1252 1337 -1867 29 -167 32 -506 5 -649 -67 -363 -223 -663 -482 -930
-403 -415 -1027 -694 -1755 -785 -140 -18 -383 -36 -477 -36 l-62 0 -4 2183
c-2 2015 -4 2192 -20 2317 -47 360 -133 634 -261 835 -164 255 -478 396 -970
435 -117 9 -363 11 -476 4z m310 -3890 l0 -1885 -57 6 c-477 53 -779 113
-1097 219 -753 252 -1225 710 -1357 1316 -28 130 -38 433 -19 575 101 759 645
1280 1578 1515 260 65 658 125 930 139 l22 1 0 -1886z"
        />
        <path
          d="M9115 41919 c-472 -48 -799 -204 -968 -462 -92 -139 -131 -263 -143
-452 -30 -489 281 -841 856 -969 287 -64 147 -60 2353 -63 1124 -2 2007 -7
2007 -12 0 -5 -6 -11 -12 -14 -7 -3 -71 -53 -142 -111 -557 -457 -936 -923
-1126 -1386 -135 -330 -198 -662 -207 -1107 -10 -426 33 -736 147 -1083 137
-419 373 -799 701 -1130 488 -494 1170 -872 1955 -1084 1107 -300 2622 -337
3829 -95 576 115 1018 259 1470 478 301 146 508 270 745 447 625 463 1045
1073 1195 1734 52 227 60 315 60 625 -1 248 -4 310 -23 430 -133 848 -581
1504 -1504 2204 l-128 96 268 6 c263 6 390 19 537 55 362 89 640 285 771 545
63 126 79 203 79 384 0 189 -17 265 -92 407 -168 318 -518 508 -1027 557 -132
13 -11476 13 -11601 0z m8285 -2093 c723 -73 1274 -248 1723 -549 595 -398
890 -1056 766 -1706 -60 -313 -209 -588 -453 -840 -174 -179 -349 -305 -596
-431 -429 -218 -884 -338 -1502 -397 -220 -21 -874 -24 -1088 -5 -553 49 -984
146 -1393 313 -705 289 -1158 782 -1263 1374 -26 149 -24 387 4 532 91 461
383 862 847 1166 478 312 1104 498 1865 557 187 14 899 5 1090 -14z"
        />
        <path
          d="M20580 32515 c-163 -29 -370 -75 -669 -149 -373 -93 -641 -143 -976
-183 -139 -17 -288 -18 -2040 -18 -2024 0 -1990 1 -2420 -51 -519 -62 -968
-173 -1300 -322 -682 -305 -1117 -856 -1320 -1672 -76 -305 -115 -561 -147
-955 -17 -214 -17 -914 0 -1105 60 -662 182 -1128 419 -1593 322 -633 722
-1040 1276 -1302 345 -163 662 -232 1016 -222 186 5 262 18 391 71 193 77 367
240 460 429 65 133 75 186 74 382 -1 195 -20 289 -77 384 -51 86 -168 161
-697 451 -304 166 -458 273 -627 433 -281 268 -415 538 -485 982 -20 124 -22
179 -23 485 0 312 2 356 22 465 96 526 292 763 768 928 252 87 671 156 1024
169 l84 3 64 -190 c273 -806 394 -1236 737 -2615 189 -760 231 -915 304 -1100
195 -494 535 -914 982 -1211 194 -130 487 -262 735 -333 375 -108 922 -143
1320 -85 961 140 1725 664 2106 1444 173 357 246 672 256 1116 10 418 -33 761
-142 1139 -164 570 -580 1234 -1192 1904 l-63 69 180 98 c706 384 1060 679
1182 984 31 77 32 85 32 225 1 162 -11 222 -69 347 -125 271 -402 511 -686
594 -117 34 -325 36 -499 4z m-2790 -2405 c884 -63 1351 -225 1752 -608 338
-323 576 -782 642 -1242 82 -566 -54 -983 -418 -1287 -273 -228 -608 -336
-986 -320 -274 12 -490 78 -665 202 -79 56 -255 240 -328 342 -80 112 -159
267 -201 393 -54 162 -163 564 -356 1305 -110 424 -237 830 -344 1099 l-55
139 407 -6 c224 -4 472 -11 552 -17z"
        />
        <path
          d="M18576 23185 c-229 -37 -456 -145 -638 -301 -116 -100 -207 -243
-245 -384 -27 -101 -24 -293 6 -390 51 -167 148 -295 324 -432 132 -101 246
-169 502 -296 480 -239 709 -395 960 -654 148 -152 228 -264 311 -433 133
-272 182 -519 171 -855 -16 -479 -193 -875 -543 -1210 -381 -364 -945 -612
-1664 -730 -282 -46 -465 -61 -820 -67 -902 -16 -1589 109 -2175 396 -267 131
-454 260 -643 445 -298 290 -454 601 -503 1000 -16 136 -6 419 20 561 37 202
123 419 224 570 194 290 576 605 1067 883 247 139 479 307 583 423 162 178
202 451 108 726 -43 126 -102 214 -221 334 -156 156 -296 236 -518 292 -75 19
-114 22 -282 22 -206 0 -256 -6 -450 -55 -288 -73 -656 -249 -925 -443 -217
-157 -551 -471 -699 -657 -327 -414 -588 -931 -709 -1405 -98 -383 -135 -724
-124 -1145 17 -637 135 -1158 376 -1655 189 -390 412 -697 737 -1015 317 -309
674 -556 1109 -766 592 -285 1272 -472 2010 -553 295 -32 445 -41 790 -48
1513 -28 2737 306 3657 996 754 565 1216 1319 1382 2251 47 264 58 398 63 745
6 350 -3 531 -38 785 -124 895 -537 1714 -1136 2253 -446 400 -868 634 -1381
762 -228 57 -516 78 -686 50z"
        />
        <path
          d="M20580 13975 c-162 -29 -355 -72 -710 -159 -369 -90 -563 -126 -893
-169 -172 -22 -175 -22 -2072 -22 -2034 0 -1988 1 -2428 -50 -951 -111 -1592
-372 -2012 -817 -510 -542 -747 -1327 -772 -2553 -18 -943 116 -1647 432
-2275 247 -489 554 -855 930 -1108 335 -225 717 -369 1093 -412 157 -18 426
-8 532 20 312 82 558 314 642 607 20 68 23 99 22 248 -1 141 -5 184 -23 250
-25 93 -72 176 -121 215 -69 54 -292 185 -631 371 -307 167 -455 269 -626 432
-283 269 -415 535 -485 982 -29 188 -37 643 -14 843 49 421 191 708 430 867
233 155 573 257 1026 310 177 20 423 36 431 27 9 -10 216 -635 293 -887 140
-458 237 -820 501 -1880 203 -815 255 -1000 342 -1204 90 -212 229 -444 376
-626 145 -179 408 -410 627 -549 110 -70 349 -186 481 -234 692 -251 1537
-239 2224 30 636 250 1124 688 1405 1263 119 243 187 463 232 746 19 126 22
183 23 454 0 437 -34 697 -141 1060 -170 577 -576 1224 -1192 1899 l-62 69
180 98 c317 172 490 281 718 452 243 181 403 367 468 543 27 73 29 89 29 219
-1 160 -13 220 -75 352 -98 209 -296 409 -515 517 -140 70 -215 88 -385 92
-121 3 -167 0 -280 -21z m-2795 -2405 c879 -60 1353 -224 1759 -609 493 -470
753 -1222 635 -1841 -64 -333 -278 -630 -589 -814 -341 -202 -804 -252 -1194
-129 -179 56 -295 132 -447 291 -122 128 -200 233 -269 364 -95 180 -123 275
-444 1498 -130 498 -246 866 -360 1149 l-46 114 403 -6 c221 -4 470 -11 552
-17z"
        />
      </g>
    </svg>
  );

  const onWindowOpen = event => {
    event.preventDefault();
    window.open('http://akademiaene.com/');
  };

  return (
    <Styles.Card href={'https://www.akademiaenebratislava.com/online'} target={'_blank'} rel={'noopener noreferrer'}>
      <Styles.Logo onClick={event => onWindowOpen(event)}>{academiaLogo}</Styles.Logo>
      <span>
        <Styles.Title>ACADEMIA EÑE LIPTOVSKÝ MIKULÁŠ</Styles.Title>
        <Styles.Subtitle>Comienza ahora tus clases con nuestros profesores online</Styles.Subtitle>
      </span>
      <Styles.Online src={online} />
    </Styles.Card>
  );
};
