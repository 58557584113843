import styled from 'styled-components';

const Card = styled('a')`
  align-items: center;
  backdrop-filter: blur(7px);
  background: var(--card);
  border-radius: 10px;
  border: 2px solid var(--card-border);
  color: var(--text);
  display: flex;
  flex-direction: column;
  height: 75vh;
  justify-content: center;
  margin: 20px;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  text-decoration: none;
  transition: all 0.2s ease;
  width: 75%;
  z-index: 2;
  -webkit-backdrop-filter: blur(7px);

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.2s ease;
  }
`;

const Logo = styled('span')`
  align-items: center;
  display: flex;
  height: 8vmin;
  left: 1rem;
  position: absolute;
  top: 1rem;
  transition: all 0.2s ease;
  width: 8vmin;
  z-index: 2;

  &:hover {
    cursor: pointer;
    transform: scale(1.5);
    transition: all 0.2s ease;
  }
`;

const Online = styled('img')`
  width: 50vmin;
  margin: 2rem;
  /* -webkit-backface-visibility: hidden;
  -ms-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  transform: translateZ(0); */
`;

const Subtitle = styled('h2')`
  font-size: 3.5vmin;
  font-weight: lighter;
  margin-top: 0.5rem;
`;

const Title = styled('h1')`
  font-size: 6vmin;
  margin: 0;
  letter-spacing: 0.5rem;
`;

export const Styles = { Card, Logo, Online, Subtitle, Title };
