import React from 'react';

import { Styles } from './ThemeButton.styles';

import { useDarkMode } from 'tools/Hooks/useDarkMode';

export const ThemeButton = () => {
  const [isDarkMode, setIsDarkMode] = useDarkMode();

  const onToggleTheme = () => setIsDarkMode(!isDarkMode);

  return (
    <Styles.Button aria-label="Activate dark mode" title="Activate dark mode" onClick={() => onToggleTheme()}>
      <div className={`item-1 ${isDarkMode ? 'sun' : 'moon'}_item-1`}></div>
      <div className={`item-2 ${isDarkMode ? 'sun' : 'moon'}_item-2`}></div>
    </Styles.Button>
  );
};
