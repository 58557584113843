import { Styles } from 'App.styles';

import { Card } from 'components/Card/Card';
import { ThemeButton } from 'components/ThemeButton/ThemeButton';

export const App = () => (
  <Styles.App>
    <Styles.Theme>
      <ThemeButton />
    </Styles.Theme>
    <Styles.Container>
      <Card />
    </Styles.Container>
  </Styles.App>
);
