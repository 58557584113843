import styled from 'styled-components';

const App = styled('div')`
  height: calc(100vh - 2rem);
  padding: 1rem;
  text-align: center;
  width: calc(100vw - 2rem);
`;

const Container = styled('div')`
  align-content: center;
  align-items: center;
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;

  @media only screen and (min-width: 480px) {
    flex-direction: row;
  }
`;

const Theme = styled('div')`
  background: var(--bg);
  border-radius: 50%;
  padding: 0.5rem;
  position: absolute;
  z-index: 2;
`;

export const Styles = { App, Container, Theme };
